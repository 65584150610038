import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'app-delivery-conditions',
  templateUrl: './delivery-conditions.component.html',
  styleUrls: ['./delivery-conditions.component.scss'],
})
export class DeliveryConditionsComponent {
  constructor(
    public title: Title,
    private router: Router
  ) {}

  goBack() {
    this.router.navigate([sessionStorage.history])
    sessionStorage.removeItem('history')
  }
}
