import { Component, OnDestroy } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { map, Subscription, take, timer } from 'rxjs'
import { DefaultService, Identity, Verification } from 'src/app/services/api'
import { SnackBarService } from 'src/app/services/snackbar.service'
import { SmartIdBasicAccountComponent } from '../smart-id-basic-account/smart-id-basic-account.component'

@Component({
  selector: 'app-smart-id-dialog',
  templateUrl: './smart-id-dialog.component.html',
  styleUrls: ['./smart-id-dialog.component.scss'],
})
export class SmartIDDialogComponent implements OnDestroy {
  readonly personIdentity = this.fb.nonNullable.control('', Validators.required)

  identity: Identity | undefined

  verificationCode: Verification | undefined

  counter: number | undefined

  readonly subscription = new Subscription()

  constructor(
    private api: DefaultService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SmartIDDialogComponent>,
    public dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  confirm(): void {
    this.personIdentity.markAsTouched()
    if (this.personIdentity.invalid) {
      return
    }

    this.api.getSmartIdVerificationCode(this.personIdentity.value).subscribe({
      next: (code) => {
        this.verificationCode = code

        this.api.getSmartIdIdentity(this.personIdentity.value).subscribe({
          next: (identity) => {
            countdown.unsubscribe()
            this.dialogRef.close(identity)
          },
          error: (err) => {
            countdown.unsubscribe()
            this.dialogRef.close()
            if (err.status === 451) {
              this.dialog.open(SmartIdBasicAccountComponent, { width: '700px' })
              return
            }
            this.snackBarService.httpError(err)
          },
        })

        // Verification code is valid for 90 seconds
        const time = 90
        const countdown = timer(0, 1000)
          .pipe(
            map((v) => time - v),
            take(time + 1)
          )
          .subscribe({ next: (v) => (this.counter = v) })

        this.subscription.add(countdown)
      },
      error: (err) => {
        this.snackBarService.httpError(err)
        this.dialogRef.close()
      },
    })
  }
}
