<mat-toolbar color="primary" class="mat-elevation-z3">
  <button mat-icon-button (click)="goBack()">
    <mat-icon>close</mat-icon>
  </button>
  <span class="title">{{ title.getTitle() }}</span>
</mat-toolbar>
<div class="container" style="line-height: 1.5; display: grid; gap: 10px; margin-bottom: 30px">
  <div style="text-align: center; font-size: 18px; padding-top: 30px">
    <p>ZĀĻU PIEGĀDE MĀJĀS</p>
    <p>PIEGĀDES NOSACĪJUMI</p>
  </div>
  <div>
    <p>1. Zāles piegādā kurjers, ar kuru noslēgts līgums par Piegādes instrukciju izpildes nodrošināšanu.</p>
    <p>
      2. Zāļu piegādes izmaksas Rīga ir 3 EUR. Ārpus Rīgas piegādi nodrošina Express Pasts. Izmaksas atbilstoši tarifam
      par zāļu piegādi un dokumentu atgriešanu. Piegādes izdevumus sedz klients.
    </p>
    <p>
      3. Narkotiskās vai psihotropās zāles personai mājās piegādā tikai aptiekas darbinieks - nodarbināts farmaceits vai
      farmaceita asistents. Par piegādes iespējām vienoties ar farmaceitu.
    </p>
    <p>
      4. Ārpus Rīgas anabolisko steroīdu, testosteronu, augšanas hormonu un vai tā analogu piegādi uz mājām nodrošina
      Express Pasta kurjers. Express Pasta kurjers nodrošina arī tādu zāļu piegādi, kurām nepieciešami īpaši
      uzglabāšanas apstākļi (piemēram, aukstuma kaste) vai zāles, kas ir viegli uzsliesmojošas (piemēram, etilspirtu
      saturošas zāles). Izmaksas atbilstoši tarifam par zāļu piegādi un dokumentu atgriešanu. Piegādes izdevumus sedz
      klients.
    </p>
    <p>5. Farmaceits sazinās ar klientu par piegādes iespējām, pēc pasūtījuma saņemšanas.</p>
    <p>6. Farmaceits sagatavo un nosūta klientam rēķinu. Klients apmaksā rēķinu ar bankas pārskaitījumu.</p>
    <p>
      7. Zāles tiek izsūtītas divu darba dienu laikā no maksājuma saņemšanas brīža, ņemot vērā zāļu pieejamību. Ja zāles
      nav pieejamas, klients par to tiek informēts.
    </p>
    <p>
      8. Piegādes dienā ar klientu sazinās kurjers, lai precizētu piegādes laiku. Express Pasts sūtījumiem klients var
      sekot līdzi arī Express Pasts mājas lapā ievadot pasūtījuma numuru.
    </p>
    <p>
      9. Ja zāļu piegādes brīdī klients nebūs sastopams norādītajā adresē, kurjers ar klientu sazināsies par tālruni. Ja
      klients nebūs sazvanāms, vai norādītajā adresē nevarēs ierasties, klientam tiks atstāts paziņojums ar norādītu
      tālruņa numuru, pa kuru sazināties ar kurjeru (attiecas uz piegādi Rīgā). Uz ārpus Rīgas sūtījumiem ir spēkā arī
      Express Pasta piegādes nosacījumi.
    </p>
    <p>10. Visas pasūtījumu piegādes tiek veiktas darba dienās.</p>
    <p>11. Saules Aptiekai ir tiesības atkāpties no augstāk minētajiem piegādes termiņiem:</p>
    <ul>
      <li>ja norādītā piegādes adrese ir kļūdaina;</li>
      <li>ja abas puses vienojas par citu piegādes laiku;</li>
      <li>ja klients neatrodas norādītajā adresē, vai nav sazvanāms.</li>
    </ul>
    <p><strong>12. Saņemtos pasūtījumus nevar atgriezt atpakaļ aptiekā.</strong></p>
  </div>
  <div>
    <p style="text-decoration: underline">Zāļu pasūtījuma komplektēšana un noformēšana.</p>
    <p>
      1. Pasūtītās zāles tiek nokomplektētas un iepakotas divos noslēgtos, necaurspīdīgos un aizzīmogotos iepakojumos,
      lai nodrošinātu pasūtījuma konfidencialitāti.
    </p>
    <p>
      2. Iepakojuma iekšpusē zālēm tiek pievienots čeks. Iepakojuma ārpusē tiek norādīts preču saņēmējs (vārds,
      uzvārds), telefona numurus, piegādes adrese, durvju kods (ja tāds ir) un cita nepieciešama informācija.
    </p>
    <p>
      3. Pie īpašiem transportēšanas nosacījumiem, zāļu sūtījums tiek atbilstoši marķēts (piemēram, uzmanīgi,
      ledusskapis vai plīstošs).
    </p>
    <p>
      4. Neskaidrību vai jautājumu gadījumā klients var sazināties ar aptieku pa e-pastu
      <a href="mailto:piegade@saulesaptieka.lv">piegade@saulesaptieka.lv</a> vai telefonu
      <a href="tel:+37167812697">+371 67 812 697</a> , <a href="tel:+37120203888">+371 20 203 888</a> darba dienās no
      8-16 (izņemot svētku dienas).
    </p>
  </div>
  <div>
    <p style="text-decoration: underline">Informācija</p>
    <p>
      1. Visi veiktie pasūtījumi tiek glabāti uzņēmuma reģistrā, un tiks uzglabāti tik ilgi, cik prasa pastāvošā LR
      likumdošana; tie ir pieejami Saules Aptiekas farmaceitiem.
    </p>
    <p>
      2. Atteikuma tiesības tiek realizētas saskaņā ar Patērētāju tiesību aizsardzības likumu, Informācijas sabiedrības
      pakalpojuma likumu, Noteikumiem par distances līgumu un farmācijas nozares likumdošanu.
    </p>
  </div>
  <div>
    <p style="text-decoration: underline">Atbildība</p>
    <p>
      1. Klients nes atbildību par informāciju, kuru viņš sniedz, veicot pasūtījumu. Ja klients norādījis datus kļūdaini
      vai neprecīzi, aptieka nenes atbildību par preces nepiegādāšanu.
    </p>
    <p>
      2. Strīdīgos jautājumus starp pārdevēju (aptieku) un pircēju risina pārrunu ceļā. Ja tas nav iespējams, tad
      strīdus risina atbilstoši LR likumdošanai.
    </p>
  </div>
</div>
