<mat-toolbar color="primary" class="mat-elevation-z3" *ngIf="!openedAsDialog">
  <button mat-icon-button (click)="goBack()">
    <mat-icon>close</mat-icon>
  </button>
  <span class="title">{{ title.getTitle() }}</span>
</mat-toolbar>
<div class="container text" [class.dialog]="openedAsDialog" [class.wrapper]="featureToggle">
  <h3 [class.dialog-header]="featureToggle">SIA „Saules aptieka” Personas datu apstrādes politika</h3>
  <p>
    Lai klientiem sniegtu kvalitatīvus pakalpojumus, SIA „Saules aptieka” ir nepieciešams vākt, apstrādāt un izmantot
    noteikta veida informāciju par fiziskām personām. Šī Personas datu apstrādes politika (turpmāk - Politika) skaidro,
    kā SIA „Saules aptieka” apstrādā fizisko personu datus, nosaka datu subjekta tiesību īstenošanas kārtību un citus ar
    personas datu aizsardzību saistītos jautājumus.
  </p>
  <p>
    SIA „Saules aptieka” kā pārzinis veic personas datu apstrādi saskaņā ar Vispārīgo datu aizsardzības regulu
    Nr.2016/679 (turpmāk - Regula), Fizisko personu datu apstrādes likumu, Pacientu tiesību likumu, Ārstniecības likumu,
    kā arī citiem Latvijā spēkā esošajiem normatīvajiem aktiem. SIA „Saules aptieka” piemērojamo normatīvo aktu ietvaros
    nodrošina personas datu konfidencialitāti un īsteno atbilstošus tehniskos un organizatoriskos pasākumus personas
    datu aizsardzībai. SIA “Saules aptieka” aizsargā personas datus, izmantojot mūsdienu tehnoloģiju iespējas, ņemot
    vērā pastāvošos privātuma riskus un saprātīgi pieejamos organizatoriskos, finansiālos un tehniskos resursus.
  </p>
  <h3>Pārzinis un tā kontaktinformācija</h3>
  <p>
    Personas datu apstrādes pārzinis - SIA “Saules aptieka”, vienotās reģistrācijas Nr.LV40003373494, juridiskā adrese:
    Krūzes iela 38, Rīga, LV-1002
  </p>
  <p>
    Kontaktinformācija, lai sazinātos par jautājumiem, kas saistīti ar personas datu apstrādi un aizsardzību e-pasts:
    <a href="mailto:datuspecialists@saulesaptieka.lv">datuspecialists@saulesaptieka.lv</a> Izmantojot šo elektroniskā
    pasta adresi vai vēršoties SIA „Saules aptieka” juridiskajā adresē, iespējams uzdot jautājumu par SIA „Saules
    aptieka” veikto personas datu apstrādi un aizsardzību. Pieprasījumu par savu tiesību īstenošanu var iesniegt
    atbilstoši šeit noteiktajai kārtībai un saskaņā ar normatīvajos aktos noteikto.
  </p>

  <h3>Personas datu iegūšana un apstrāde</h3>

  <p>
    SIA “Saules aptieka” pamatā iegūst personas datus no datu subjekta, sniedzot farmaceitiskās aprūpes pakalpojumus,
    vai no sadarbības partneriem, valsts iestādēm vai trešajām personām, ja tam ir tiesisks pamats. SIA “Saules aptieka”
    iegūst personas datus arī caur SIA “Saules aptieka” interneta vietni:
    <a href="https://www.saulesaptieka.lv/" target="_blank">www.saulesaptieka.lv</a> ,
    <a href="https://mana.saulesaptieka.lv/" target="_blank">mana.saulesaptieka.lv</a> un
    <a href="https://www.webaptieka.lv/lv/" target="_blank">www.webaptieka.lv</a> . Datu subjekta personas dati tiek
    apstrādāti papīra vai elektroniskā formātā. Personas datu apstrādi veic pilnvaroti SIA „Saules aptieka” darbinieki,
    kā arī SIA „Saules aptieka” apstrādātāji, ar kuriem ir noslēgts atbilstošs līgums. Pārsvarā personas datu apstrāde
    notiek, lai SIA „Saules aptieka” varētu sniegt klientiem farmaceitisko aprūpi, izpildītu kādu no normatīvajos aktos
    tai noteiktajiem pienākumiem vai tiesībām.
  </p>
  <p>
    Lai SIA „Saules aptieka” nodrošinātu farmaceitisko aprūpi klientiem, var būt nepieciešams veikt arī īpašu kategoriju
    personas datu apstrādi.
  </p>
  <p>
    SIA „Saules aptieka” piemēro Politiku privātuma un personas datu aizsardzības nodrošināšanai attiecībā uz Klientiem:
  </p>
  <ul>
    <li>
      fiziskajām personām - klientiem (tajā skaitā, potenciālajiem, bijušajiem un esošajiem) un citiem pakalpojumu
      lietotājiem, kā arī trešajām personām, kuras saistībā ar pakalpojumu sniegšanu fiziskai personai saņem vai nodod
      SIA “Saules aptieka” jebkādu informāciju;
    </li>
    <li>darbiniekiem (tajā skaitā, potenciālajiem, bijušajiem un esošajiem)</li>
    <li>interneta aptiekas un uzturēto interneta mājaslapu apmeklētājiem.</li>
  </ul>

  <h3>Personas datu apstrādes nolūki/ mērķi, tiesiskie pamati un principi</h3>

  <p>
    SIA „Saules Aptieka” personas datu apstrādei veic šādiem mērķiem tikai tad, ja pastāv tiesiskais pamats to darīt:
  </p>
  <ul>
    <li>
      <strong>Farmaceitiskās aprūpes nodrošināšanai</strong> (t.sk. sniedzot pakalpojumus digitālajāvidē) - pakalpojumu
      sniegšanai, preču piegādei un garantijas pakalpojumu sniegšanai (līguma saistību izpildei), aptiekas piedāvātā
      sortimenta un pakalpojumu uzlabošanai, attīstībai un reklamēšanai (t.sk. nodrošinot komerciālu paziņojumu
      nosūtīšanu līdz brīdim, kamēr spēkā ir klienta piekrišana); saziņai ar klientiem; klientu aptauju veikšanai.
    </li>
    <li>
      <strong>Farmaceitiskās aprūpes plānošanai un analītikai-</strong> statistikai un biznesa analīzei; plānošanai un
      uzskaitei; efektivitātes mērīšanai; tirgus un sabiedriskā viedokļa pētījumu veikšanai; atskaišu sagatavošanai.
    </li>
    <li>
      <strong>Efektīvai uzņēmuma pārvaldības procesa nodrošināšanai,</strong> tai skaitā sabiedrības informēšanai par
      uzņēmuma darbu; klientu iebildumu izskatīšanai; klientu noturēšanai, lojalitātes sekmēšanai; norēķinu
      administrēšanai; mājaslapu uzturēšanai un darbības uzlabošanai; nodrošinot informācijas sniegšanu valsts un
      pašvaldību institūcijām normatīvajos aktos noteiktajā kārtībā.
    </li>
    <li>
      <strong>Personāla vadībai</strong> - attiecībā uz potenciālajiem, esošajiem un bijušajiem darbiniekiem personāla
      atlases un vadības procesu nodrošināšanai.
    </li>
  </ul>

  <h3>Datu aizsardzības principi</h3>

  <p>SIA “Saules aptieka” apstrādā personas datus:</p>
  <ul>
    <li>likumīgi, godprātīgi un datu subjektam pārredzamā veidā;</li>
    <li>adekvāti, atbilstīgi un ietver tikai tos, kas nepieciešami apstrādes nolūku sasniegšanai;</li>
    <li>
      tikai tādā veidā, lai tiktu nodrošināta atbilstoša personas datu drošība, tostarp aizsardzība pret neatļautu vai
      nelikumīgu apstrādi un pret nejaušu nozaudēšanu, iznīcināšanu vai sabojāšanu, izmantojot atbilstošus tehniskos vai
      organizatoriskos pasākumus;
    </li>
    <li>saglabājot personas datus ne ilgāk kā nepieciešams nolūkiem, kādiem tie apstrādāti;</li>
    <li>informējot klientus, kāpēc ir nepieciešama personas datu apstrāde.</li>
  </ul>
  <h3>Personas datu glabāšanas ilgums</h3>
  <p>
    SIA “Saules aptieka” glabā personas datus atbilstoši definētajiem personas datu apstrādes mērķiem un personas datu
    apstrādes tiesiskajiem pamatiem, kamēr pastāv vismaz viens no šiem kritērijiem:
  </p>
  <ul>
    <li>pastāv juridisks pienākums datus glabāt;</li>
    <li>ir nepieciešams realizēt savas leģitīmās intereses;</li>
    <li>ir spēkā datu subjekta piekrišana attiecīgai personas datu apstrādei.</li>
  </ul>

  <p>
    Atsevišķos gadījumos SIA “Saules aptieka” saglabā Jūsu personas datus arhīvā, lai saglabātu pierādījumus,
    vēsturiskiem mērķiem vai tos izmanto statistiskiem mērķiem. Laika periods, cik ilgi SIA „Saules aptieka” saglabās
    personas datus, ir atkarīgs no tā, kādam nolūkam tie ir tikuši ievākti, kā arī nodrošinot datu saglabāšanas prasības
    saskaņā normatīvajiem aktiem.
  </p>

  <h3>Personas datu saņēmēji</h3>

  <p>
    SIA “Saules aptieka” nenodod informāciju trešajām personām bez tiesiska pamata, kā arī normatīvajos aktos noteiktajā
    kārtībā reģistrē personas, kuras ieguvušas informāciju no SIA “Saules aptieka”.
  </p>

  <h3>Personas datu aizsardzība</h3>

  <p>
    SIA „Saules aptieka” nodrošina, pastāvīgi pārskata un uzlabo personu datu aizsardzības pasākumus, lai aizsargātu
    fizisko personu personas datus no nesankcionētas piekļuves, nejaušas nozaudēšanas, izpaušanas vai iznīcināšanas. Lai
    to nodrošinātu, SIA „Saules aptieka” izmanto atbilstošas tehniskās un organizatoriskās prasības. SIA „Saules
    aptieka” rūpīgi izvērtē visus pakalpojuma sniedzējus, kas SIA „Saules aptieka” vārdā un uzdevumā apstrādā fizisko
    personu personas datus, kā arī nosaka, lai pakalpojuma sniedzēji (personas datu apstrādātāji) pielieto atbilstošus
    drošības pasākumus, lai fizisko personu personas datu apstrāde notiktu atbilstoši SIA „Saules aptieka” deleģējumam
    un normatīvo aktu prasībām.
  </p>

  <h3>Datu subjekta tiesības</h3>

  <ul>
    <li>Tiesības tikt informētam par to, kāpēc SIA „Saules aptieka” apstrādā personas datus.</li>
    <li>
      Tiesības pieprasīt informāciju par to, kādus personas datus SIA „Saules aptieka” par konkrēto klientu saglabā. Šo
      informāciju mēs sniedzam bez maksas. Mums ir pienākums sniegt atbildi viena mēneša laikā, ja vien mēs nepagarinām
      atbildes sniegšanu līdz diviem mēnešiem, ņemot vērā pieprasījuma sarežģītību un skaitu.
    </li>
    <li>
      Tiesības labot datus. Klientam ir tiesības labot datus, ja tie ir neprecīzi vai nepilnīgi. Mums ir pienākums
      sniegt atbildi viena mēneša laikā. Ja mēs nolemsim nelabot datus, mēs Jums sniegsim skaidrojumu, kāpēc mēs tos
      nelabosim, un sniegsim Jums informāciju par Jūsu tiesībām iesniegt sūdzību Datu valsts inspekcijā.
    </li>
    <li>
      Tiesības uz datu dzēšanu (tiesības “tikt aizmirstam”). Klientam ir tiesības lūgt dzēst savus personas datus vai
      pārtraukt to apstrādi. Mēs ne vienmēr varēsim izpildīt Jūsu lūgumu, piemēram, ja mums būs pienākums saglabāt
      informāciju. Ja mēs nolemsim nedzēst datus, mēs Jums sniegsim skaidrojumu, kāpēc mēs nedzēsīsim datus, un sniegsim
      Jums informāciju par Jūsu tiesībām iesniegt sūdzību Datu valsts inspekcijā.
    </li>
    <li>
      Tiesības ierobežot datu apstrādi. Klientam atsevišķos gadījumos ir tiesības ierobežot savu personas datu apstrādi,
      piemēram, ja informācija nav precīza. Ja notiks personas datu apstrādes ierobežošana, mēs varam saglabāt par to
      attiecīgu informāciju, lai nākotnē varētu nodrošināt šo tiesību ievērošanu. Ja mēs nolemsim atcelt šādu
      ierobežojumu, tad mēs Jums par to paziņosim.
    </li>
    <li>
      Tiesības uz datu pārnesamību. Ja mēs apstrādājam personas datus saskaņā ar klienta (datu subjekta) piekrišanu vai
      pamatojoties uz līgumu, un tie ir saglabāti strukturētā, plaši izmantotā un mašīnlasāmā formātā, klientam ir
      tiesības lūgt personas datu nosūtīšanu tieši no viena pārziņa citam pārzinim, ja tas ir tehniski iespējams.
    </li>
    <li>
      Tiesības iebilst. Klients var iebilst pret savu personas datu izmantošanu tiešās tirgvedības nolūkos vai
      zinātniskās vai vēstures pētniecības nolūkos vai statistikas nolūkos, izņemot, ja apstrāde ir vajadzīga, lai
      izpildītu uzdevumu, ko veic sabiedrības interesēs.
    </li>
  </ul>

  <h3>Personas datu aizsardzības pārkāpumi, sūdzības un komentāri</h3>

  <h3>Datu aizsardzības pārkāpums</h3>

  <p>
    Ja Jums ir bažas par to, kā mēs veicam Jūsu personas datu apstrādi, vai Jūs domājiet, ka kaut kas ir noticis
    neatbilstoši, piemēram, ja Jūs saņemiet vēstuli no SIA “Saules aptieka”, kura nav adresēta Jums, lūdzu sazinieties
    ar SIA “Saules aptieka” datu aizsardzības speciālistu, rakstot uz SIA “Saules aptieka” e-pastu:
    <a href="mailto:datuspecialists@saulesaptieka.lv">datuspecialists@saulesaptieka.lv</a>
    , ziņojot par iespējamu personas datu aizsardzības pārkāpumu.
  </p>

  <p>
    Personas datu drošības incidenta gadījumā, ja tas radīs iespējami augstu risku datu subjekta tiesībām un brīvībām,
    SIA „Saules aptieka” paziņos par to attiecīgajam datu subjektam, vai informācija tiks publiskota SIA „Saules
    aptieka” mājaslapā vai citādā iespējamā veidā, kā arī nodrošinās informācijas sniegšanu Datu valsts inspekcijai.
  </p>

  <h3>Sūdzības un komentāri</h3>

  <p>
    Ja Jūs vēlaties iesniegt sūdzību vai sniegt komentārus par to kā SIA “Saules aptieka” apstrādā personas datus, Jūs
    variet rakstīt uz e-pastu: <em>datuspecialists@saulesaptieka.lv.</em> Ja Jūs joprojām neesiet apmierināts ar to, kā
    SIA “Saules aptieka” izskata Jūsu sūdzību par veikto personas datu apstrādi, Jūs variet kontaktēties ar:
  </p>
  <p>
    <strong>Datu valsts inspekciju,</strong> Blaumaņa iela 11/13-11, Rīga, LV-1011, Tālrunis:
    <a href="tel:+37167223131">+371 67 22 31 31</a> ; Fakss: 67 22 35 56; E-pasts:
    <a href="mailto:info@dvi.gov.lv">info@dvi.gov.lv</a>.
  </p>

  <h3>Izmaiņas Politikā</h3>

  <p>
    SIA “Saules Aptieka” ir tiesības jebkurā brīdī izdarīt izmaiņas šajā Politikā, kuras tiek publicētas interneta
    vietnē <a href="https://www.saulesaptieka.lv/" target="_blank">www.saulesaptieka.lv</a> un
    <a href="https://mana.saulesaptieka.lv/" target="_blank">mana.saulesaptieka.lv</a>
    kā arī izvietotas SIA „Saules aptieka” telpās.
  </p>
  <ng-container *ngIf="!featureToggle; else new">
    <mat-dialog-actions align="end" *ngIf="openedAsDialog">
      <button mat-button mat-dialog-close>Atcelt</button>
      <button color="primary" mat-flat-button [mat-dialog-close]="true">Piekrītu</button>
    </mat-dialog-actions>
  </ng-container>
</div>

<ng-template #new>
  <mat-dialog-actions align="end" class="dialog-footer">
    <button mat-button mat-dialog-close>Atcelt</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="true">Piekrītu un apmaksāt</button>
  </mat-dialog-actions>
</ng-template>
