import { Component, Input } from '@angular/core'

import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'app-electronic-identification-terms',
  templateUrl: './electronic-identification-terms.component.html',
  styleUrls: ['./electronic-identification-terms.component.scss'],
})
export class ElectronicIdentificationTermsComponent {
  @Input() openedAsDialog = false

  constructor(
    public title: Title,
    private router: Router
  ) {}

  goBack() {
    this.router.navigate([sessionStorage.history])
    sessionStorage.removeItem('history')
  }
}
