/**
 * API
 * API. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Address } from '../model/models';
import { CheckResponse } from '../model/models';
import { Client } from '../model/models';
import { Discount } from '../model/models';
import { Document } from '../model/models';
import { ErrorResponse } from '../model/models';
import { EventName } from '../model/models';
import { ExternalProduct } from '../model/models';
import { ExternalProductPrice } from '../model/models';
import { FmdBulkItem } from '../model/models';
import { FmdBulkItemResult } from '../model/models';
import { FmdBulkTransaction } from '../model/models';
import { FmdPack } from '../model/models';
import { Identity } from '../model/models';
import { InsurerPaymentSummaryReport } from '../model/models';
import { InsurerPaymentsReport } from '../model/models';
import { InsurerType } from '../model/models';
import { Invoice } from '../model/models';
import { InvoiceItem } from '../model/models';
import { InvoiceType } from '../model/models';
import { Order } from '../model/models';
import { OrderPaymentLink } from '../model/models';
import { OrderPaymentState } from '../model/models';
import { OrderState } from '../model/models';
import { Pack } from '../model/models';
import { PackState } from '../model/models';
import { ParseResponse } from '../model/models';
import { PatchPackState } from '../model/models';
import { Product } from '../model/models';
import { ProductType } from '../model/models';
import { Receipt } from '../model/models';
import { ReportOrigin } from '../model/models';
import { StockItem } from '../model/models';
import { Verification } from '../model/models';
import { VerifyResponse } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DefaultService {

    protected basePath = 'https://api.saulesaptieka.lv/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * calculate receipt total
     * @param receipt Receipt passed for calculation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateReceipt(receipt: Receipt, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Receipt>;
    public calculateReceipt(receipt: Receipt, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Receipt>>;
    public calculateReceipt(receipt: Receipt, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Receipt>>;
    public calculateReceipt(receipt: Receipt, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (receipt === null || receipt === undefined) {
            throw new Error('Required parameter receipt was null or undefined when calling calculateReceipt.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Receipt>(`${this.configuration.basePath}/receipts/calc`,
            receipt,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * capture EveryPay payment event for orders (EveryPay APIv4 Integration Documentation (09.03.2022 revision))
     * @param paymentReference EveryPay payment reference ID
     * @param eventName EveryPay payment event name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public capturePaymentEvent(paymentReference?: string, eventName?: EventName, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public capturePaymentEvent(paymentReference?: string, eventName?: EventName, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public capturePaymentEvent(paymentReference?: string, eventName?: EventName, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public capturePaymentEvent(paymentReference?: string, eventName?: EventName, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (paymentReference !== undefined && paymentReference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paymentReference, 'payment_reference');
        }
        if (eventName !== undefined && eventName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eventName, 'event_name');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/orders/events/every-pay-event`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new client (consumer or business).
     * @param client 
     * @param identity JWS with person\&#39;s identity.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createClient(client: Client, identity?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Client>;
    public createClient(client: Client, identity?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Client>>;
    public createClient(client: Client, identity?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Client>>;
    public createClient(client: Client, identity?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (client === null || client === undefined) {
            throw new Error('Required parameter client was null or undefined when calling createClient.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identity !== undefined && identity !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identity, 'identity');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Client>(`${this.configuration.basePath}/clients`,
            client,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create client address
     * @param clientId 
     * @param address 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createClientAddress(clientId: number, address: Address, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Address>;
    public createClientAddress(clientId: number, address: Address, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Address>>;
    public createClientAddress(clientId: number, address: Address, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Address>>;
    public createClientAddress(clientId: number, address: Address, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling createClientAddress.');
        }
        if (address === null || address === undefined) {
            throw new Error('Required parameter address was null or undefined when calling createClientAddress.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Address>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(clientId))}/addresses`,
            address,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create client document
     * @param clientId 
     * @param document 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createClientDocument(clientId: number, document: Document, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Document>;
    public createClientDocument(clientId: number, document: Document, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Document>>;
    public createClientDocument(clientId: number, document: Document, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Document>>;
    public createClientDocument(clientId: number, document: Document, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling createClientDocument.');
        }
        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling createClientDocument.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Document>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(clientId))}/documents`,
            document,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new invoice.
     * @param invoice 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInvoice(invoice: Invoice, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Invoice>;
    public createInvoice(invoice: Invoice, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Invoice>>;
    public createInvoice(invoice: Invoice, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Invoice>>;
    public createInvoice(invoice: Invoice, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (invoice === null || invoice === undefined) {
            throw new Error('Required parameter invoice was null or undefined when calling createInvoice.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Invoice>(`${this.configuration.basePath}/invoices`,
            invoice,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new invoice item
     * @param invoiceId 
     * @param invoiceItem 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInvoiceItem(invoiceId: number, invoiceItem: InvoiceItem, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InvoiceItem>;
    public createInvoiceItem(invoiceId: number, invoiceItem: InvoiceItem, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InvoiceItem>>;
    public createInvoiceItem(invoiceId: number, invoiceItem: InvoiceItem, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InvoiceItem>>;
    public createInvoiceItem(invoiceId: number, invoiceItem: InvoiceItem, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling createInvoiceItem.');
        }
        if (invoiceItem === null || invoiceItem === undefined) {
            throw new Error('Required parameter invoiceItem was null or undefined when calling createInvoiceItem.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InvoiceItem>(`${this.configuration.basePath}/invoices/${encodeURIComponent(String(invoiceId))}/items`,
            invoiceItem,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create order
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrder(order: Order, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Order>;
    public createOrder(order: Order, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Order>>;
    public createOrder(order: Order, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Order>>;
    public createOrder(order: Order, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (order === null || order === undefined) {
            throw new Error('Required parameter order was null or undefined when calling createOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Order>(`${this.configuration.basePath}/orders`,
            order,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new pack, also executes FMD verification to acquire pack\&#39;s state.
     * @param pack 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPack(pack: Pack, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Pack>;
    public createPack(pack: Pack, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Pack>>;
    public createPack(pack: Pack, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Pack>>;
    public createPack(pack: Pack, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (pack === null || pack === undefined) {
            throw new Error('Required parameter pack was null or undefined when calling createPack.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Pack>(`${this.configuration.basePath}/packs`,
            pack,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new product.
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProduct(product: Product, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Product>;
    public createProduct(product: Product, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Product>>;
    public createProduct(product: Product, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Product>>;
    public createProduct(product: Product, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling createProduct.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Product>(`${this.configuration.basePath}/products`,
            product,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete client address
     * @param clientId 
     * @param id Address id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteClientAddress(clientId: number, id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteClientAddress(clientId: number, id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteClientAddress(clientId: number, id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteClientAddress(clientId: number, id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling deleteClientAddress.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteClientAddress.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(clientId))}/addresses/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete pack
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePack(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deletePack(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deletePack(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deletePack(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deletePack.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/packs/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark one pack as destroyed
     * Request the destruction for one pack described by code read from 2D barcode 
     * @param fmdPack Code string passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public destroyPack(fmdPack: FmdPack, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VerifyResponse>;
    public destroyPack(fmdPack: FmdPack, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VerifyResponse>>;
    public destroyPack(fmdPack: FmdPack, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VerifyResponse>>;
    public destroyPack(fmdPack: FmdPack, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdPack === null || fmdPack === undefined) {
            throw new Error('Required parameter fmdPack was null or undefined when calling destroyPack.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<VerifyResponse>(`${this.configuration.basePath}/fmd/destroy`,
            fmdPack,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Dispense one pack
     * Request the dispense of one pack described by code read from 2D barcode 
     * @param fmdPack Code string passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dispensePack(fmdPack: FmdPack, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VerifyResponse>;
    public dispensePack(fmdPack: FmdPack, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VerifyResponse>>;
    public dispensePack(fmdPack: FmdPack, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VerifyResponse>>;
    public dispensePack(fmdPack: FmdPack, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdPack === null || fmdPack === undefined) {
            throw new Error('Required parameter fmdPack was null or undefined when calling dispensePack.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<VerifyResponse>(`${this.configuration.basePath}/fmd/dispense`,
            fmdPack,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * A two step asynchronous request consisting of (1) sending the main request and (2) periodically polling for the response.
     * Multiple pack requests (various types) described by code read from 2D barcode 
     * @param fmdBulkItem array passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fmdBulk(fmdBulkItem: Array<FmdBulkItem>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FmdBulkTransaction>;
    public fmdBulk(fmdBulkItem: Array<FmdBulkItem>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FmdBulkTransaction>>;
    public fmdBulk(fmdBulkItem: Array<FmdBulkItem>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FmdBulkTransaction>>;
    public fmdBulk(fmdBulkItem: Array<FmdBulkItem>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdBulkItem === null || fmdBulkItem === undefined) {
            throw new Error('Required parameter fmdBulkItem was null or undefined when calling fmdBulk.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<FmdBulkTransaction>(`${this.configuration.basePath}/fmd/mixed_bulk`,
            fmdBulkItem,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get client by id.
     * @param id 
     * @param expand Expand references
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClient(id: number, expand?: Array<'addresses'>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Client>;
    public getClient(id: number, expand?: Array<'addresses'>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Client>>;
    public getClient(id: number, expand?: Array<'addresses'>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Client>>;
    public getClient(id: number, expand?: Array<'addresses'>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getClient.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Client>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get addresses by clientId
     * @param clientId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientAddresses(clientId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Address>>;
    public getClientAddresses(clientId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Address>>>;
    public getClientAddresses(clientId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Address>>>;
    public getClientAddresses(clientId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling getClientAddresses.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Address>>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(clientId))}/addresses`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get documents by client id.
     * @param clientId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientDocuments(clientId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Document>>;
    public getClientDocuments(clientId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Document>>>;
    public getClientDocuments(clientId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Document>>>;
    public getClientDocuments(clientId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling getClientDocuments.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Document>>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(clientId))}/documents`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of clients.
     * @param expand Expand references
     * @param filterIds Filter one or more clients by ids
     * @param rangeStart Set returned item range - start
     * @param rangeEnd Set returned item range - end
     * @param filterSearch Case insensitive search
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClients(expand?: Array<'addresses' | 'documents'>, filterIds?: Array<number>, rangeStart?: number, rangeEnd?: number, filterSearch?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Client>>;
    public getClients(expand?: Array<'addresses' | 'documents'>, filterIds?: Array<number>, rangeStart?: number, rangeEnd?: number, filterSearch?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Client>>>;
    public getClients(expand?: Array<'addresses' | 'documents'>, filterIds?: Array<number>, rangeStart?: number, rangeEnd?: number, filterSearch?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Client>>>;
    public getClients(expand?: Array<'addresses' | 'documents'>, filterIds?: Array<number>, rangeStart?: number, rangeEnd?: number, filterSearch?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }
        if (filterIds) {
            queryParameters = this.addToHttpParams(queryParameters,
                filterIds.join(COLLECTION_FORMATS['csv']), 'filterIds');
        }
        if (rangeStart !== undefined && rangeStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeStart, 'rangeStart');
        }
        if (rangeEnd !== undefined && rangeEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeEnd, 'rangeEnd');
        }
        if (filterSearch !== undefined && filterSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterSearch, 'filterSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Client>>(`${this.configuration.basePath}/clients`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDiscounts(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Discount>>;
    public getDiscounts(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Discount>>>;
    public getDiscounts(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Discount>>>;
    public getDiscounts(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Discount>>(`${this.configuration.basePath}/discounts`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieves person\&#39;s identity using provided access code
     * @param accessCode access code obtained from e-paraksts authorization operation
     * @param redirectUrl URL for redirecting user to after eParaksts identity verification process is completed.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEparakstsIdentity(accessCode: string, redirectUrl: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Identity>;
    public getEparakstsIdentity(accessCode: string, redirectUrl: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Identity>>;
    public getEparakstsIdentity(accessCode: string, redirectUrl: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Identity>>;
    public getEparakstsIdentity(accessCode: string, redirectUrl: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accessCode === null || accessCode === undefined) {
            throw new Error('Required parameter accessCode was null or undefined when calling getEparakstsIdentity.');
        }
        if (redirectUrl === null || redirectUrl === undefined) {
            throw new Error('Required parameter redirectUrl was null or undefined when calling getEparakstsIdentity.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (redirectUrl !== undefined && redirectUrl !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>redirectUrl, 'redirectUrl');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Identity>(`${this.configuration.basePath}/identity/e-paraksts/${encodeURIComponent(String(accessCode))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param filterClientId 
     * @param expand Expand references
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExternalProductPrices(filterClientId?: number, expand?: Array<'client' | 'product' | 'externalProduct' | 'product.manufacturer'>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ExternalProductPrice>>;
    public getExternalProductPrices(filterClientId?: number, expand?: Array<'client' | 'product' | 'externalProduct' | 'product.manufacturer'>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ExternalProductPrice>>>;
    public getExternalProductPrices(filterClientId?: number, expand?: Array<'client' | 'product' | 'externalProduct' | 'product.manufacturer'>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ExternalProductPrice>>>;
    public getExternalProductPrices(filterClientId?: number, expand?: Array<'client' | 'product' | 'externalProduct' | 'product.manufacturer'>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterClientId !== undefined && filterClientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterClientId, 'filterClientId');
        }
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ExternalProductPrice>>(`${this.configuration.basePath}/external/products/prices`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of all products from external systems
     * @param expand Expand references
     * @param filterOrigin filter by origin (horizon, etc)
     * @param filterEmptyProduct if false - finds all with ref to product, true - without ref to product
     * @param filterSearch 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExternalProducts(expand?: Array<'product'>, filterOrigin?: string, filterEmptyProduct?: boolean, filterSearch?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ExternalProduct>>;
    public getExternalProducts(expand?: Array<'product'>, filterOrigin?: string, filterEmptyProduct?: boolean, filterSearch?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ExternalProduct>>>;
    public getExternalProducts(expand?: Array<'product'>, filterOrigin?: string, filterEmptyProduct?: boolean, filterSearch?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ExternalProduct>>>;
    public getExternalProducts(expand?: Array<'product'>, filterOrigin?: string, filterEmptyProduct?: boolean, filterSearch?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }
        if (filterOrigin !== undefined && filterOrigin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterOrigin, 'filterOrigin');
        }
        if (filterEmptyProduct !== undefined && filterEmptyProduct !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterEmptyProduct, 'filterEmptyProduct');
        }
        if (filterSearch !== undefined && filterSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterSearch, 'filterSearch');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ExternalProduct>>(`${this.configuration.basePath}/external/products`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieves insurers\&#39; payment summary report
     * @param filterDateFrom Filter insurers\&#39; payments by date (from)
     * @param filterDateTo Filter insurers\&#39; payments by date (to)
     * @param filterType Filter by insurer type
     * @param filterOrigin Filter by origin, web-store or legacy.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInsurerPaymentSummaryReport(filterDateFrom?: string, filterDateTo?: string, filterType?: InsurerType, filterOrigin?: ReportOrigin, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InsurerPaymentSummaryReport>;
    public getInsurerPaymentSummaryReport(filterDateFrom?: string, filterDateTo?: string, filterType?: InsurerType, filterOrigin?: ReportOrigin, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InsurerPaymentSummaryReport>>;
    public getInsurerPaymentSummaryReport(filterDateFrom?: string, filterDateTo?: string, filterType?: InsurerType, filterOrigin?: ReportOrigin, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InsurerPaymentSummaryReport>>;
    public getInsurerPaymentSummaryReport(filterDateFrom?: string, filterDateTo?: string, filterType?: InsurerType, filterOrigin?: ReportOrigin, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterDateFrom !== undefined && filterDateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterDateFrom, 'filterDateFrom');
        }
        if (filterDateTo !== undefined && filterDateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterDateTo, 'filterDateTo');
        }
        if (filterType !== undefined && filterType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterType, 'filterType');
        }
        if (filterOrigin !== undefined && filterOrigin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterOrigin, 'filterOrigin');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InsurerPaymentSummaryReport>(`${this.configuration.basePath}/reports/insurer-payment-summary`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieves insurers\&#39; payments report
     * @param filterDateFrom Filter insurers\&#39; payments by date (from)
     * @param filterDateTo Filter insurers\&#39; payments by date (to)
     * @param filterType Filter by insurer type
     * @param filterOrigin Filter by origin, web-store or legacy.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInsurerPaymentsReport(filterDateFrom?: string, filterDateTo?: string, filterType?: InsurerType, filterOrigin?: ReportOrigin, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InsurerPaymentsReport>;
    public getInsurerPaymentsReport(filterDateFrom?: string, filterDateTo?: string, filterType?: InsurerType, filterOrigin?: ReportOrigin, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InsurerPaymentsReport>>;
    public getInsurerPaymentsReport(filterDateFrom?: string, filterDateTo?: string, filterType?: InsurerType, filterOrigin?: ReportOrigin, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InsurerPaymentsReport>>;
    public getInsurerPaymentsReport(filterDateFrom?: string, filterDateTo?: string, filterType?: InsurerType, filterOrigin?: ReportOrigin, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterDateFrom !== undefined && filterDateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterDateFrom, 'filterDateFrom');
        }
        if (filterDateTo !== undefined && filterDateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterDateTo, 'filterDateTo');
        }
        if (filterType !== undefined && filterType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterType, 'filterType');
        }
        if (filterOrigin !== undefined && filterOrigin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterOrigin, 'filterOrigin');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InsurerPaymentsReport>(`${this.configuration.basePath}/reports/insurer-payments`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get invoice by id.
     * @param id 
     * @param expand Expand referenced properties
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoice(id: number, expand?: Array<'client' | 'items.product' | 'items.packs' | 'items.externalProduct'>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Invoice>;
    public getInvoice(id: number, expand?: Array<'client' | 'items.product' | 'items.packs' | 'items.externalProduct'>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Invoice>>;
    public getInvoice(id: number, expand?: Array<'client' | 'items.product' | 'items.packs' | 'items.externalProduct'>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Invoice>>;
    public getInvoice(id: number, expand?: Array<'client' | 'items.product' | 'items.packs' | 'items.externalProduct'>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getInvoice.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Invoice>(`${this.configuration.basePath}/invoices/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param filterInvoiceIds Filter one or more invoice by InvoiceIds
     * @param filterClientId Filter by client id.
     * @param filterTypes Filter by invoice type.
     * @param expand Expand referenced properties
     * @param sort Sort list by invoice fields - field name followed by comma separated optional sort direction (\&#39;asc\&#39; or \&#39;desc\&#39;), \&#39;asc\&#39; by default. 
     * @param rangeStart Set returned item range - start
     * @param rangeEnd Set returned item range - end
     * @param filterDateFrom Filter invoices by date (from)
     * @param filterDateTo Filter invoices by date (to)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoices(filterInvoiceIds?: Array<number>, filterClientId?: number, filterTypes?: Array<InvoiceType>, expand?: Array<'client' | 'items.product' | 'items.packs' | 'items.externalProduct'>, sort?: Array<string>, rangeStart?: number, rangeEnd?: number, filterDateFrom?: string, filterDateTo?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Invoice>>;
    public getInvoices(filterInvoiceIds?: Array<number>, filterClientId?: number, filterTypes?: Array<InvoiceType>, expand?: Array<'client' | 'items.product' | 'items.packs' | 'items.externalProduct'>, sort?: Array<string>, rangeStart?: number, rangeEnd?: number, filterDateFrom?: string, filterDateTo?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Invoice>>>;
    public getInvoices(filterInvoiceIds?: Array<number>, filterClientId?: number, filterTypes?: Array<InvoiceType>, expand?: Array<'client' | 'items.product' | 'items.packs' | 'items.externalProduct'>, sort?: Array<string>, rangeStart?: number, rangeEnd?: number, filterDateFrom?: string, filterDateTo?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Invoice>>>;
    public getInvoices(filterInvoiceIds?: Array<number>, filterClientId?: number, filterTypes?: Array<InvoiceType>, expand?: Array<'client' | 'items.product' | 'items.packs' | 'items.externalProduct'>, sort?: Array<string>, rangeStart?: number, rangeEnd?: number, filterDateFrom?: string, filterDateTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterInvoiceIds) {
            queryParameters = this.addToHttpParams(queryParameters,
                filterInvoiceIds.join(COLLECTION_FORMATS['csv']), 'filterInvoiceIds');
        }
        if (filterClientId !== undefined && filterClientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterClientId, 'filterClientId');
        }
        if (filterTypes) {
            filterTypes.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterTypes');
            })
        }
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sort');
            })
        }
        if (rangeStart !== undefined && rangeStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeStart, 'rangeStart');
        }
        if (rangeEnd !== undefined && rangeEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeEnd, 'rangeEnd');
        }
        if (filterDateFrom !== undefined && filterDateFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterDateFrom, 'filterDateFrom');
        }
        if (filterDateTo !== undefined && filterDateTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterDateTo, 'filterDateTo');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Invoice>>(`${this.configuration.basePath}/invoices`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get order by id.
     * @param id 
     * @param expand Expand referenced properties
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrder(id: number, expand?: Array<'address' | 'client' | 'receipt.markdowns.discount.offeredBy' | 'receipt.items.markdowns.discount.offeredBy' | 'receipt.payers.client'>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Order>;
    public getOrder(id: number, expand?: Array<'address' | 'client' | 'receipt.markdowns.discount.offeredBy' | 'receipt.items.markdowns.discount.offeredBy' | 'receipt.payers.client'>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Order>>;
    public getOrder(id: number, expand?: Array<'address' | 'client' | 'receipt.markdowns.discount.offeredBy' | 'receipt.items.markdowns.discount.offeredBy' | 'receipt.payers.client'>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Order>>;
    public getOrder(id: number, expand?: Array<'address' | 'client' | 'receipt.markdowns.discount.offeredBy' | 'receipt.items.markdowns.discount.offeredBy' | 'receipt.payers.client'>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrder.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Order>(`${this.configuration.basePath}/orders/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get orders
     * @param filterOrderIds Filter one or more order by OrderIds
     * @param filterClientId Filter by client id.
     * @param filterState Filter by order state
     * @param filterPaymentReference Filter by payment reference
     * @param expand Expand referenced properties
     * @param rangeStart Set returned item range - start
     * @param rangeEnd Set returned item range - end
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrders(filterOrderIds?: Array<number>, filterClientId?: number, filterState?: OrderState, filterPaymentReference?: string, expand?: Array<'address' | 'client' | 'receipt.markdowns.discount.offeredBy' | 'receipt.items.markdowns.discount.offeredBy' | 'receipt.payers.client'>, rangeStart?: number, rangeEnd?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Order>>;
    public getOrders(filterOrderIds?: Array<number>, filterClientId?: number, filterState?: OrderState, filterPaymentReference?: string, expand?: Array<'address' | 'client' | 'receipt.markdowns.discount.offeredBy' | 'receipt.items.markdowns.discount.offeredBy' | 'receipt.payers.client'>, rangeStart?: number, rangeEnd?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Order>>>;
    public getOrders(filterOrderIds?: Array<number>, filterClientId?: number, filterState?: OrderState, filterPaymentReference?: string, expand?: Array<'address' | 'client' | 'receipt.markdowns.discount.offeredBy' | 'receipt.items.markdowns.discount.offeredBy' | 'receipt.payers.client'>, rangeStart?: number, rangeEnd?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Order>>>;
    public getOrders(filterOrderIds?: Array<number>, filterClientId?: number, filterState?: OrderState, filterPaymentReference?: string, expand?: Array<'address' | 'client' | 'receipt.markdowns.discount.offeredBy' | 'receipt.items.markdowns.discount.offeredBy' | 'receipt.payers.client'>, rangeStart?: number, rangeEnd?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterOrderIds) {
            queryParameters = this.addToHttpParams(queryParameters,
                filterOrderIds.join(COLLECTION_FORMATS['csv']), 'filterOrderIds');
        }
        if (filterClientId !== undefined && filterClientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterClientId, 'filterClientId');
        }
        if (filterState !== undefined && filterState !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterState, 'filterState');
        }
        if (filterPaymentReference !== undefined && filterPaymentReference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterPaymentReference, 'filterPaymentReference');
        }
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }
        if (rangeStart !== undefined && rangeStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeStart, 'rangeStart');
        }
        if (rangeEnd !== undefined && rangeEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeEnd, 'rangeEnd');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Order>>(`${this.configuration.basePath}/orders`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieves EveryPay payment link for the specified order
     * @param orderId order identification number
     * @param customerRedirectUrl URL for redirecting customer after payment process is completed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentLink(orderId: number, customerRedirectUrl?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<OrderPaymentLink>;
    public getPaymentLink(orderId: number, customerRedirectUrl?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<OrderPaymentLink>>;
    public getPaymentLink(orderId: number, customerRedirectUrl?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<OrderPaymentLink>>;
    public getPaymentLink(orderId: number, customerRedirectUrl?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getPaymentLink.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (customerRedirectUrl !== undefined && customerRedirectUrl !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerRedirectUrl, 'customerRedirectUrl');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<OrderPaymentLink>(`${this.configuration.basePath}/orders/${encodeURIComponent(String(orderId))}/every-pay-payment-link`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieves EveryPay payment state for the specified order
     * @param orderId order identification number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentState(orderId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<OrderPaymentState>;
    public getPaymentState(orderId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<OrderPaymentState>>;
    public getPaymentState(orderId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<OrderPaymentState>>;
    public getPaymentState(orderId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getPaymentState.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<OrderPaymentState>(`${this.configuration.basePath}/orders/${encodeURIComponent(String(orderId))}/every-pay-payment-state`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get product information
     * Request information about a product
     * @param barcode Pack barcode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductInfo(barcode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CheckResponse>;
    public getProductInfo(barcode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CheckResponse>>;
    public getProductInfo(barcode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CheckResponse>>;
    public getProductInfo(barcode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (barcode === null || barcode === undefined) {
            throw new Error('Required parameter barcode was null or undefined when calling getProductInfo.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (barcode !== undefined && barcode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>barcode, 'barcode');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CheckResponse>(`${this.configuration.basePath}/fmd/check`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of all product types
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductTypes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ProductType>>;
    public getProductTypes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ProductType>>>;
    public getProductTypes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ProductType>>>;
    public getProductTypes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ProductType>>(`${this.configuration.basePath}/productTypes`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param rangeStart Set returned item range - start
     * @param rangeEnd Set returned item range - end
     * @param filterSearch Case insensitive search
     * @param filterProductTypeIds Filter products by productTypeIds
     * @param expand 
     * @param sort Sort list by product fields - field name followed by comma separated optional sort direction (\&#39;asc\&#39; or \&#39;desc\&#39;), \&#39;asc\&#39; by default. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProducts(rangeStart?: number, rangeEnd?: number, filterSearch?: string, filterProductTypeIds?: Array<number>, expand?: Array<'manufacturer' | 'type' | 'unit'>, sort?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Product>>;
    public getProducts(rangeStart?: number, rangeEnd?: number, filterSearch?: string, filterProductTypeIds?: Array<number>, expand?: Array<'manufacturer' | 'type' | 'unit'>, sort?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Product>>>;
    public getProducts(rangeStart?: number, rangeEnd?: number, filterSearch?: string, filterProductTypeIds?: Array<number>, expand?: Array<'manufacturer' | 'type' | 'unit'>, sort?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Product>>>;
    public getProducts(rangeStart?: number, rangeEnd?: number, filterSearch?: string, filterProductTypeIds?: Array<number>, expand?: Array<'manufacturer' | 'type' | 'unit'>, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (rangeStart !== undefined && rangeStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeStart, 'rangeStart');
        }
        if (rangeEnd !== undefined && rangeEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeEnd, 'rangeEnd');
        }
        if (filterSearch !== undefined && filterSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterSearch, 'filterSearch');
        }
        if (filterProductTypeIds) {
            queryParameters = this.addToHttpParams(queryParameters,
                filterProductTypeIds.join(COLLECTION_FORMATS['csv']), 'filterProductTypeIds');
        }
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sort');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Product>>(`${this.configuration.basePath}/products`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieves person\&#39;s identity using provided identity number.
     * @param identityNumber person\&#39;s identity number
     * @param countryCode country code for identity number issuing country. List of supported country codes - LV, LT, EE.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmartIdIdentity(identityNumber: string, countryCode?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Identity>;
    public getSmartIdIdentity(identityNumber: string, countryCode?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Identity>>;
    public getSmartIdIdentity(identityNumber: string, countryCode?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Identity>>;
    public getSmartIdIdentity(identityNumber: string, countryCode?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (identityNumber === null || identityNumber === undefined) {
            throw new Error('Required parameter identityNumber was null or undefined when calling getSmartIdIdentity.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (countryCode !== undefined && countryCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>countryCode, 'countryCode');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Identity>(`${this.configuration.basePath}/identity/smart-id/${encodeURIComponent(String(identityNumber))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieves verification code for Smart-ID session.
     * @param identityNumber person\&#39;s identity number
     * @param countryCode country code for identity number issuing country. List of supported country codes - LV, LT, EE.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmartIdVerificationCode(identityNumber: string, countryCode?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Verification>;
    public getSmartIdVerificationCode(identityNumber: string, countryCode?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Verification>>;
    public getSmartIdVerificationCode(identityNumber: string, countryCode?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Verification>>;
    public getSmartIdVerificationCode(identityNumber: string, countryCode?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (identityNumber === null || identityNumber === undefined) {
            throw new Error('Required parameter identityNumber was null or undefined when calling getSmartIdVerificationCode.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (countryCode !== undefined && countryCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>countryCode, 'countryCode');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Verification>(`${this.configuration.basePath}/identity/smart-id/verification-code/${encodeURIComponent(String(identityNumber))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieves stock balance
     * @param rangeStart Set returned item range - start
     * @param rangeEnd Set returned item range - end
     * @param filterProductIds Filter one or more stockItems by ProductIds
     * @param filterProductTypeIds Filter stockItems by productTypeIds
     * @param expand Expand references
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStockBalance(rangeStart?: number, rangeEnd?: number, filterProductIds?: Array<number>, filterProductTypeIds?: Array<number>, expand?: Array<'unit' | 'product' | 'product.manufacturer' | 'product.unit'>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<StockItem>>;
    public getStockBalance(rangeStart?: number, rangeEnd?: number, filterProductIds?: Array<number>, filterProductTypeIds?: Array<number>, expand?: Array<'unit' | 'product' | 'product.manufacturer' | 'product.unit'>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<StockItem>>>;
    public getStockBalance(rangeStart?: number, rangeEnd?: number, filterProductIds?: Array<number>, filterProductTypeIds?: Array<number>, expand?: Array<'unit' | 'product' | 'product.manufacturer' | 'product.unit'>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<StockItem>>>;
    public getStockBalance(rangeStart?: number, rangeEnd?: number, filterProductIds?: Array<number>, filterProductTypeIds?: Array<number>, expand?: Array<'unit' | 'product' | 'product.manufacturer' | 'product.unit'>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (rangeStart !== undefined && rangeStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeStart, 'rangeStart');
        }
        if (rangeEnd !== undefined && rangeEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rangeEnd, 'rangeEnd');
        }
        if (filterProductIds) {
            filterProductIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'filterProductIds');
            })
        }
        if (filterProductTypeIds) {
            queryParameters = this.addToHttpParams(queryParameters,
                filterProductTypeIds.join(COLLECTION_FORMATS['csv']), 'filterProductTypeIds');
        }
        if (expand) {
            expand.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'expand');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<StockItem>>(`${this.configuration.basePath}/stock`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * A two step asynchronous request consisting of (1) sending the main request and (2) periodically polling for the response
     * Mixed bulk result 
     * @param fmdBulkTransaction Code string passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mixedBulkResult(fmdBulkTransaction: FmdBulkTransaction, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FmdBulkItemResult>>;
    public mixedBulkResult(fmdBulkTransaction: FmdBulkTransaction, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FmdBulkItemResult>>>;
    public mixedBulkResult(fmdBulkTransaction: FmdBulkTransaction, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FmdBulkItemResult>>>;
    public mixedBulkResult(fmdBulkTransaction: FmdBulkTransaction, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdBulkTransaction === null || fmdBulkTransaction === undefined) {
            throw new Error('Required parameter fmdBulkTransaction was null or undefined when calling mixedBulkResult.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<FmdBulkItemResult>>(`${this.configuration.basePath}/fmd/mixed_bulk_result`,
            fmdBulkTransaction,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Parse string
     * Gets parsed structure by POSTing the string
     * @param fmdPack Input string passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public parsePackCode(fmdPack: FmdPack, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ParseResponse>;
    public parsePackCode(fmdPack: FmdPack, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ParseResponse>>;
    public parsePackCode(fmdPack: FmdPack, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ParseResponse>>;
    public parsePackCode(fmdPack: FmdPack, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdPack === null || fmdPack === undefined) {
            throw new Error('Required parameter fmdPack was null or undefined when calling parsePackCode.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ParseResponse>(`${this.configuration.basePath}/fmd/parse`,
            fmdPack,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch client (current implementation only affects phone numbers and verification state, identity based on provided JWS with person\&#39;s identity)
     * @param id 
     * @param client 
     * @param identity JWS with person\&#39;s identity.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchClient(id: number, client: Client, identity?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Client>;
    public patchClient(id: number, client: Client, identity?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Client>>;
    public patchClient(id: number, client: Client, identity?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Client>>;
    public patchClient(id: number, client: Client, identity?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchClient.');
        }
        if (client === null || client === undefined) {
            throw new Error('Required parameter client was null or undefined when calling patchClient.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identity !== undefined && identity !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identity, 'identity');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Client>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(id))}`,
            client,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Only product ref can be patched, no other field. NOTE - changing external product id also affects invoice item records!
     * @param id 
     * @param externalProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchExternalProduct(id: number, externalProduct: ExternalProduct, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExternalProduct>;
    public patchExternalProduct(id: number, externalProduct: ExternalProduct, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExternalProduct>>;
    public patchExternalProduct(id: number, externalProduct: ExternalProduct, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExternalProduct>>;
    public patchExternalProduct(id: number, externalProduct: ExternalProduct, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchExternalProduct.');
        }
        if (externalProduct === null || externalProduct === undefined) {
            throw new Error('Required parameter externalProduct was null or undefined when calling patchExternalProduct.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<ExternalProduct>(`${this.configuration.basePath}/external/products/${encodeURIComponent(String(id))}`,
            externalProduct,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice partially.
     * @param id 
     * @param invoice 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchInvoice(id: number, invoice: Invoice, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Invoice>;
    public patchInvoice(id: number, invoice: Invoice, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Invoice>>;
    public patchInvoice(id: number, invoice: Invoice, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Invoice>>;
    public patchInvoice(id: number, invoice: Invoice, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchInvoice.');
        }
        if (invoice === null || invoice === undefined) {
            throw new Error('Required parameter invoice was null or undefined when calling patchInvoice.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Invoice>(`${this.configuration.basePath}/invoices/${encodeURIComponent(String(id))}`,
            invoice,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch order (current implementation only affects order state field)
     * @param id Order id
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchOrder(id: number, order: Order, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Order>;
    public patchOrder(id: number, order: Order, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Order>>;
    public patchOrder(id: number, order: Order, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Order>>;
    public patchOrder(id: number, order: Order, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchOrder.');
        }
        if (order === null || order === undefined) {
            throw new Error('Required parameter order was null or undefined when calling patchOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Order>(`${this.configuration.basePath}/orders/${encodeURIComponent(String(id))}`,
            order,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Apply new state to all filtered packs
     * @param filterInvoiceId Invoice id
     * @param filterState Pack state
     * @param patchPackState 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchPacks(filterInvoiceId: number, filterState: PackState, patchPackState: PatchPackState, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public patchPacks(filterInvoiceId: number, filterState: PackState, patchPackState: PatchPackState, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public patchPacks(filterInvoiceId: number, filterState: PackState, patchPackState: PatchPackState, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public patchPacks(filterInvoiceId: number, filterState: PackState, patchPackState: PatchPackState, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (filterInvoiceId === null || filterInvoiceId === undefined) {
            throw new Error('Required parameter filterInvoiceId was null or undefined when calling patchPacks.');
        }
        if (filterState === null || filterState === undefined) {
            throw new Error('Required parameter filterState was null or undefined when calling patchPacks.');
        }
        if (patchPackState === null || patchPackState === undefined) {
            throw new Error('Required parameter patchPackState was null or undefined when calling patchPacks.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterInvoiceId !== undefined && filterInvoiceId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterInvoiceId, 'filterInvoiceId');
        }
        if (filterState !== undefined && filterState !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterState, 'filterState');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/packs`,
            patchPackState,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark the pack as a sample
     * Request sample for one pack described by code read from 2D barcode 
     * @param fmdPack Code string passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public samplePack(fmdPack: FmdPack, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VerifyResponse>;
    public samplePack(fmdPack: FmdPack, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VerifyResponse>>;
    public samplePack(fmdPack: FmdPack, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VerifyResponse>>;
    public samplePack(fmdPack: FmdPack, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdPack === null || fmdPack === undefined) {
            throw new Error('Required parameter fmdPack was null or undefined when calling samplePack.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<VerifyResponse>(`${this.configuration.basePath}/fmd/sample`,
            fmdPack,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Undo pack dispense
     * Undo pack dispense for one pack described by code read from 2D barcode 
     * @param fmdPack Code string passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public undoDispensePack(fmdPack: FmdPack, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VerifyResponse>;
    public undoDispensePack(fmdPack: FmdPack, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VerifyResponse>>;
    public undoDispensePack(fmdPack: FmdPack, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VerifyResponse>>;
    public undoDispensePack(fmdPack: FmdPack, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdPack === null || fmdPack === undefined) {
            throw new Error('Required parameter fmdPack was null or undefined when calling undoDispensePack.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<VerifyResponse>(`${this.configuration.basePath}/fmd/undo_dispense`,
            fmdPack,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Undo pack sample
     * Undo pack sample for one pack described by code read from 2D barcode 
     * @param fmdPack Code string passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public undoSamplePack(fmdPack: FmdPack, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VerifyResponse>;
    public undoSamplePack(fmdPack: FmdPack, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VerifyResponse>>;
    public undoSamplePack(fmdPack: FmdPack, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VerifyResponse>>;
    public undoSamplePack(fmdPack: FmdPack, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdPack === null || fmdPack === undefined) {
            throw new Error('Required parameter fmdPack was null or undefined when calling undoSamplePack.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<VerifyResponse>(`${this.configuration.basePath}/fmd/undo_sample`,
            fmdPack,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update client.
     * @param id 
     * @param client 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClient(id: number, client: Client, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Client>;
    public updateClient(id: number, client: Client, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Client>>;
    public updateClient(id: number, client: Client, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Client>>;
    public updateClient(id: number, client: Client, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateClient.');
        }
        if (client === null || client === undefined) {
            throw new Error('Required parameter client was null or undefined when calling updateClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Client>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(id))}`,
            client,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update address by clientId and address id
     * @param clientId 
     * @param id Address id
     * @param address 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClientAddress(clientId: number, id: number, address: Address, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Address>;
    public updateClientAddress(clientId: number, id: number, address: Address, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Address>>;
    public updateClientAddress(clientId: number, id: number, address: Address, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Address>>;
    public updateClientAddress(clientId: number, id: number, address: Address, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling updateClientAddress.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateClientAddress.');
        }
        if (address === null || address === undefined) {
            throw new Error('Required parameter address was null or undefined when calling updateClientAddress.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Address>(`${this.configuration.basePath}/clients/${encodeURIComponent(String(clientId))}/addresses/${encodeURIComponent(String(id))}`,
            address,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice.
     * @param id 
     * @param invoice 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInvoice(id: number, invoice: Invoice, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Invoice>;
    public updateInvoice(id: number, invoice: Invoice, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Invoice>>;
    public updateInvoice(id: number, invoice: Invoice, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Invoice>>;
    public updateInvoice(id: number, invoice: Invoice, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateInvoice.');
        }
        if (invoice === null || invoice === undefined) {
            throw new Error('Required parameter invoice was null or undefined when calling updateInvoice.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Invoice>(`${this.configuration.basePath}/invoices/${encodeURIComponent(String(id))}`,
            invoice,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update order
     * @param id Order id
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrder(id: number, order: Order, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Order>;
    public updateOrder(id: number, order: Order, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Order>>;
    public updateOrder(id: number, order: Order, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Order>>;
    public updateOrder(id: number, order: Order, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateOrder.');
        }
        if (order === null || order === undefined) {
            throw new Error('Required parameter order was null or undefined when calling updateOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Order>(`${this.configuration.basePath}/orders/${encodeURIComponent(String(id))}`,
            order,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update product.
     * @param id 
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProduct(id: number, product: Product, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Product>;
    public updateProduct(id: number, product: Product, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Product>>;
    public updateProduct(id: number, product: Product, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Product>>;
    public updateProduct(id: number, product: Product, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateProduct.');
        }
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling updateProduct.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Product>(`${this.configuration.basePath}/products/${encodeURIComponent(String(id))}`,
            product,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify one pack
     * Request the verification of one pack described by code read from 2D barcode 
     * @param fmdPack Code string passed in POST body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyPack(fmdPack: FmdPack, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<VerifyResponse>;
    public verifyPack(fmdPack: FmdPack, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<VerifyResponse>>;
    public verifyPack(fmdPack: FmdPack, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<VerifyResponse>>;
    public verifyPack(fmdPack: FmdPack, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fmdPack === null || fmdPack === undefined) {
            throw new Error('Required parameter fmdPack was null or undefined when calling verifyPack.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["apiKeyAuth"] || this.configuration.apiKeys["X-API-KEY"];
            if (key) {
                headers = headers.set('X-API-KEY', key);
            }
        }

        // authentication (jwtBearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<VerifyResponse>(`${this.configuration.basePath}/fmd/verify`,
            fmdPack,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
