import { Component, OnInit } from '@angular/core'
import { Auth } from '@angular/fire/auth'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser'
import { getIdTokenResult } from 'firebase/auth'
import { BehaviorSubject, combineLatest, filter, from, map, shareReplay, switchMap, tap } from 'rxjs'
import { Address, Client, DefaultService } from 'src/app/services/api'
import { SnackBarService } from 'src/app/services/snackbar.service'
import { AddressComponent } from '../address/address.component'
import { NewPhoneComponent } from '../new-phone/new-phone.component'

interface PhoneNumber {
  number: FormControl<string>
}

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  readonly form = this.fb.nonNullable.group({
    phones: this.fb.nonNullable.array<FormGroup<PhoneNumber>>([]),
  })

  loading = true

  readonly clientId = from(getIdTokenResult(this.auth.currentUser!)).pipe(
    map((token) => +(token.claims.clientId as string)),
    shareReplay(1)
  )

  private refreshClient = new BehaviorSubject<void>(undefined)

  readonly client = combineLatest({ subject: this.refreshClient, clientId: this.clientId }).pipe(
    switchMap(({ clientId }) => this.api.getClient(clientId, ['addresses'])),
    tap(() => (this.loading = false))
  )

  constructor(
    public title: Title,
    private auth: Auth,
    private api: DefaultService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.client.pipe(map((client) => client.phones)).subscribe((phones) => {
      phones?.forEach((v) => {
        this.form.controls.phones.push(
          this.fb.group<PhoneNumber>({
            number: this.fb.nonNullable.control(v.number),
          })
        )
      })
    })
  }

  editAddress(address: Address): void {
    const editDialog = this.dialog.open(AddressComponent, { data: address })
    editDialog.componentInstance.edit = true
    editDialog
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe((address) =>
        this.clientId.pipe(switchMap((id) => this.api.updateClientAddress(id, address.id!, address))).subscribe({
          next: () => this.refreshClient.next(),
          error: (err) => this.snackBarService.httpError(err),
        })
      )
  }

  editPhone(client: Client, phone: string, index: number): void {
    const number = phone.substring(4)
    const dialog = this.dialog.open(NewPhoneComponent, { width: '500px', data: number })
    dialog.componentInstance.edit = true
    dialog
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe((number) => {
        const newClient: Client = {
          ...client,
          phones: client.phones?.map((v, i) => {
            if (index === i) {
              v.number = `+371${number}`
            }

            return v
          }),
        }

        this.clientId
          .pipe(switchMap((clientId) => this.api.patchClient(clientId, newClient)))
          .subscribe({ next: () => this.refreshClient.next(), error: (err) => this.snackBarService.httpError(err) })
      })
  }

  addAddress(): void {
    this.dialog
      .open(AddressComponent, { autoFocus: false, width: '700px' })
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe({
        next: (v) => {
          const address = v.getRawValue()
          this.clientId.pipe(switchMap((clientId) => this.api.createClientAddress(clientId, address))).subscribe({
            next: () => this.refreshClient.next(),
            error: (err) => this.snackBarService.httpError(err),
          })
        },
      })
  }

  addNewPhone(client: Client): void {
    this.dialog
      .open(NewPhoneComponent, { width: '500px' })
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe((number) =>
        this.clientId
          .pipe(
            switchMap((clientId) =>
              this.api.patchClient(clientId, {
                ...client,
                phones: [
                  ...client.phones!,
                  { number: `+371${number}`, type: number.startsWith('2') ? 'mobile' : 'home' },
                ],
              })
            )
          )
          .subscribe({ next: () => this.refreshClient.next(), error: (err) => this.snackBarService.httpError(err) })
      )
  }

  deleteNumber(client: Client, index: number): void {
    const phones = client.phones?.filter((_, i) => i !== index)

    this.clientId
      .pipe(switchMap((clientId) => this.api.patchClient(clientId, { ...client, phones })))
      .subscribe({ next: () => this.refreshClient.next(), error: (err) => this.snackBarService.httpError(err) })
  }
}
