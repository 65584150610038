/**
 * API
 * API. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrderState = 'pending' | 'quote' | 'paid' | 'packing' | 'completed' | 'canceled';

export const OrderState = {
    Pending: 'pending' as OrderState,
    Quote: 'quote' as OrderState,
    Paid: 'paid' as OrderState,
    Packing: 'packing' as OrderState,
    Completed: 'completed' as OrderState,
    Canceled: 'canceled' as OrderState
};

