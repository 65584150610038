import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-sign-up-done',
  templateUrl: './sign-up-done.component.html',
  styleUrls: ['./sign-up-done.component.scss'],
})
export class SignUpDoneComponent implements OnInit {
  constructor(public title: Title) {}

  ngOnInit(): void {
    this.title.setTitle('Paldies par reģistrāciju!')
  }
}
