import { animate, animation, group, query, style } from '@angular/animations'

export const slideInAnimation = animation(
  [
    query(':enter, :leave', style({ position: '{{ position }}', width: '{{ width }}' }), { optional: true }),
    group([
      query(
        ':enter',
        [style({ transform: '{{ transform1 }}' }), animate('{{ time }}', style({ transform: '{{ transform2 }}' }))],
        { optional: true }
      ),
      query(
        ':leave',
        [style({ transform: '{{ transform2 }}' }), animate('{{ time }}', style({ transform: '{{ transform3 }}' }))],
        { optional: true }
      ),
    ]),
  ],
  {
    params: {
      position: 'fixed',
      width: '100%',
      transform1: 'translateX(-100%)',
      time: '0.5s ease-in-out',
      transform2: 'translateX(0%)',
      transform3: 'translateX(100%)',
    },
  }
)
