import { animate, state, style, transition, trigger } from '@angular/animations'
import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Observable, map, shareReplay, switchMap, tap } from 'rxjs'
import { Address } from 'src/app/services/api'

export interface AddressForm {
  addressLine1: FormControl<string>
  city: FormControl<string>
  postCode: FormControl<string>
}

interface LookupAddress {
  postCode: string
  street?: string
  village?: string
  parish?: string
  region?: string
  city?: string
  organization?: string
}

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  animations: [
    trigger('showAddress', [
      state('show', style({ transform: 'translateY(0%)' })),
      state(
        'hide',
        style({
          transform: 'translateY(0%)',
          height: '75px',
        })
      ),
      transition('show => hide', [animate('0.25s ease-out')]),
      transition('hide => show', [animate('0.5s ease-in')]),
    ]),
  ],
})
export class AddressComponent implements OnInit {
  @ViewChild('addressInput') addressInput!: ElementRef<HTMLInputElement>
  @ViewChild('postCodeInput') postCodeInput!: ElementRef<HTMLInputElement>
  @ViewChild(MatAutocompleteTrigger) addressesSuggestionsAuto!: MatAutocompleteTrigger

  readonly address = this.newAddress()

  showAddress = false

  edit = false

  // addresses lookup by post code (number)
  readonly addressesLookup = (this.http.get('/assets/addresses.json') as Observable<LookupAddress[]>).pipe(
    map((addresses) =>
      addresses.reduce((r, v) => {
        const k = +v.postCode.substr(3)
        if (r.has(k)) {
          r.get(k)?.push(v)
        } else {
          r.set(k, [v])
        }
        return r
      }, new Map<number, LookupAddress[]>())
    ),
    shareReplay(1)
  )

  readonly addressesSuggestions = this.address.controls.postCode.valueChanges.pipe(
    map((v) => +v),
    tap((v) => {
      if (v < 1000) {
        this.showAddress = false
      } else {
        this.addressesSuggestionsAuto.openPanel()
      }
    }),
    switchMap((v) =>
      this.addressesLookup.pipe(
        map((lookup) => lookup.get(v)),
        // with side effects
        map((addresses) => {
          const postCode = 'LV-' + v
          // show address if post code is entered, but no addresses found
          if (v >= 1000) {
            if (!addresses) {
              this.selectAddressSuggestion({ postCode })
              return []
            }

            if (addresses!.length === 1) {
              this.selectAddressSuggestion(addresses[0])
              return []
            }
          }

          return addresses?.concat({ city: '', postCode })
        })
      )
    )
  )

  constructor(
    public dialogRef: MatDialogRef<AddressComponent>,
    private fb: FormBuilder,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: Address
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.postCodeInput.nativeElement.focus())

    if (this.edit) {
      this.showAddress = true
      this.address.setValue({
        addressLine1: this.data.addressLine1 || '',
        city: this.data.city || '',
        postCode: this.data.postCode || '',
      })
    }
  }

  addAddress(): void {
    this.address.markAllAsTouched()
    if (this.address.invalid) {
      this.address.updateValueAndValidity()
      return
    }

    if (this.edit) {
      const { addressLine1, city, postCode } = this.address.getRawValue()
      const newAddress = { ...this.data, addressLine1, city, postCode }
      this.dialogRef.close(newAddress)
      return
    }

    const copy = this.newAddress()
    const { postCode, ...value } = this.address.getRawValue()
    copy.setValue({ postCode: `LV-${postCode}`, ...value })

    this.dialogRef.close(copy)
  }

  addressSuggestion(v: LookupAddress): string {
    return [v.street, v.village, v.parish, v.city, v.region].filter((x) => x).join(', ')
  }

  displayPostCode(v: LookupAddress): string {
    return v?.postCode || 'LV-'
  }

  errMsg(control: FormControl<string>): string {
    const { postCode, city, addressLine1 } = this.address.controls

    if (control.hasError('required')) {
      switch (control) {
        case postCode:
          return 'Pasta indekss ir nepieciešams'
        case city:
          return 'Pilsēta ir nepieciešama'
        case addressLine1:
          return 'Adrese ir nepieciešama'
      }
    }

    return ''
  }

  selectAddressSuggestion(v: LookupAddress): void {
    const addressParts = [v.organization, v.street].filter((x) => x)
    const address = addressParts.length > 0 ? addressParts.join(', ') + ' ' : ''
    const city = [v.village, v.parish, v.region, v.city].filter((x) => x).join(', ')

    this.address.controls.addressLine1.setValue(address)
    this.address.controls.city.setValue(city)

    this.showAddress = true

    if (city) {
      this.addressInput.nativeElement.focus()
    }
  }

  private newAddress(): FormGroup<AddressForm> {
    return this.fb.nonNullable.group<AddressForm>({
      addressLine1: this.fb.nonNullable.control('', { validators: Validators.required }),
      city: this.fb.nonNullable.control('', { validators: Validators.required }),
      postCode: this.fb.nonNullable.control('', { validators: Validators.required }),
    })
  }
}
