<mat-toolbar color="primary" class="mat-elevation-z3">
  <mat-toolbar-row>
    <button mat-icon-button [routerLink]="['/orders']">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span style="padding-left: 40px; font-size: 20px">{{ title.getTitle() }}</span>
  </mat-toolbar-row>
</mat-toolbar>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<main class="identity">
  <div class="flex gap-20" *ngIf="client?.identityVerification === 'pending'; else verified">
    <div class="container__icon">
      <mat-icon color="primary" fontIcon="warning" class="identity__icon"></mat-icon>
    </div>
    <span class="align-self-center">Jūsu identitāte nav verificēta.</span>
  </div>
  <div *ngIf="client?.identityVerification === 'pending' || page === 'unverified'">
    <p>Lūdzu, verificējiet savu identitāti tiešsaistē, izmantojot Smart-ID vai eParaksts.</p>

    <form [formGroup]="agreement">
      <div class="flex align-items-center">
        <mat-slide-toggle formControlName="electronicIdentificationAgree"></mat-slide-toggle>

        <span class="agreement__text"
          >Es piekrītu
          <span (click)="openTerms()" class="agreement__link"
            ><em>elektroniskās identifikācijas risinājuma lietošanas noteikumiem</em></span
          >
        </span>
      </div>
      <mat-error [class.hidden]="(!agreement.touched && agreement.invalid) || agreement.valid"
        >Piekrišana ir nepieciešama</mat-error
      >

      <div class="padding">
        <button type="button" mat-raised-button (click)="openSmartIDDialog()">
          <img src="/assets/images/sign-up/smart-id-logo.svg" alt="Smart-ID logo" class="logo__smartID" /></button
        >&nbsp;
        <button type="button" mat-raised-button (click)="openPopUp()">
          <img src="/assets/images/sign-up/eparaksts-logo.svg" alt="eParaksts logo" style="height: 20px" />
        </button>
      </div>
    </form>
  </div>
  <form [formGroup]="person" class="flex column">
    <mat-form-field appearance="outline">
      <mat-label>Vārds</mat-label>
      <input autocomplete="given-name" matInput formControlName="firstName" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Uzvārds</mat-label>
      <input autocomplete="family-name" matInput formControlName="lastName" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Personas kods</mat-label>
      <input
        matInput
        autocomplete="off"
        formControlName="identityNumber"
        mask="000000-00000"
        [dropSpecialCharacters]="false"
      />
    </mat-form-field>
  </form>
</main>

<ng-template #verified>
  <div class="flex gap-20 padding-bottom">
    <div class="container__icon flex align-self-center">
      <mat-icon color="primary" fontIcon="verified" class="identity__icon"></mat-icon>
    </div>
    <div class="flex column justify-content-center gap-10">
      <span class="identity__statuss-text">Jūsu identitāte ir verificēta.</span>
      <button
        type="button"
        mat-button
        color="primary"
        class="identity__verification-button"
        (click)="page = 'unverified'"
      >
        Veikt verifikāciju
      </button>
    </div>
  </div>
</ng-template>
