<div class="home-container">
  <div class="home">
    <div class="home__button">
      <button class="button" mat-raised-button color="primary" (click)="openSignIn()">PIESLĒGTIES</button>
    </div>
    <img src="/assets/images/home/mana-sa-logo.svg" alt="Saules aptiekas logo" class="home__logo" />
    <h1 class="slogan">Jo mums rūp Jūsu rītdiena!</h1>
    <div [class.hide]="!showsIcon" class="arrow-container">
      <mat-icon class="icon-arrow">arrow_downward</mat-icon>
    </div>
  </div>
  <div class="row row-delivery">
    <div class="row1">
      <div class="one circle circle-two circle-large"></div>
      <div class="two circle-group">
        <span class="circle circle-bottom-left circle-four"></span>
        <span class="circle circle-bottom-right circle-two"></span>
      </div>
      <span class="three circle circle-two"></span>
      <span class="four circle circle-five"></span>
      <div class="five circle-group">
        <span class="circle circle-top-left circle-four circle-small"></span>
        <span class="circle circle-top-right circle-four circle-small"></span>
      </div>
      <span class="six circle circle-six circle-medium"></span>
      <div class="seven circle-group">
        <span class="circle circle-bottom-left circle-six"></span>
        <span class="circle circle-top-right circle-three"></span>
      </div>
    </div>
    <div class="pie pie-top row2">
      <span class="shape"></span>
      Šajā ikdienas steigā jāpaspēj parūpēties arī par savu veselību! Tādēļ<br />
      <span class="highlight">
        Saules aptieka piedāvā iespēju pasūtīt sev izrakstītās zāles, nestāvot rindā, un saņemt tās mājās vai citā sev
        ērtā vietā.</span
      >
    </div>
  </div>
  <div class="row row-service">
    <div class="pie pie-bottom row2">
      <span class="shape"></span>

      <span class="highlight">Jums tiks sniegta personīga un profesionāla farmaceitiskā aprūpe!</span> <br />
      Mūsu farmaceiti atbildēs uz jūsu jautājumiem par zālēm un to lietošanu, kā arī izskaidros radušos jautājumus par
      jums nozīmēto terapiju.
    </div>
    <div class="row1">
      <span class="one circle circle-two circle-large"></span>
      <span class="two circle circle-four circle-bottom-left"></span>
      <span class="three circle circle-six"></span>
      <span class="four circle circle-five circle-medium"></span>
      <span class="five circle circle-two"></span>
      <span class="six circle circle-bottom-left circle-five"></span>
      <span class="seven circle circle-top-right circle-three"></span>
      <span class="eight circle circle-bottom-right circle-two"></span>
    </div>
  </div>
  <div class="row row-personal">
    <div class="row1">
      <span class="one circle circle-three circle-bottom-left"></span>
      <span class="two circle circle-four circle-bottom-right"></span>
      <span class="three circle circle-five"></span>
      <span class="four circle circle-two circle-top-left"></span>
      <span class="five circle circle-six circle-top-right"></span>
      <span class="six circle circle-two circle-large"></span>
      <span class="seven circle circle-three circle-bottom-right"></span>
      <span class="eight circle circle-two"></span>
      <span class="nine circle circle-six circle-medium"></span>
    </div>
    <div class="pie pie-top pie-top2 row2">
      <span class="shape"></span>
      Ja jums ārsts zāles izrakstījis elektroniski, pasūtiet tās mūsu mājas lapā! Mēs sagatavosim jums piedāvājumu un

      <span class="highlight"
        >parūpēsimies par zāļu piegādi uz jūsu norādīto adresi. Ja ērtāk, izņem savu pasūtījumu aptiekā!</span
      >
    </div>
  </div>
  <div class="row row-process">
    <div class="pie pie-left row3">
      <div class="process">
        <div class="highlight">Kā tas notiek?</div>
        <br />
        <ol class="list">
          <li><span class="icon icon-one"></span>Ārsts izraksta e-recepti pacientam</li>
          <li><span class="icon icon-two"></span>Pacients pasūta zāles</li>
          <li>
            <span class="icon icon-three"></span
            ><span
              >Farmaceits noskaidro papildu detaļas, sagatavo piedāvājumu un sniedz likumā noteikto farmaceitisko
              aprūpi.</span
            >
          </li>
          <li>
            <span class="icon icon-four"></span>Pacients apmaksā zāļu piedāvājumu ar bankas karti vai ar pārskaitījumu
          </li>
          <li><span class="icon icon-five"></span>Zāles tiek piegādātas uz pacienta izvēlēto adresi vai aptieku.</li>
        </ol>
      </div>
    </div>
    <div>
      <span class="one circle circle-two circle-bottom-right"></span>
      <span class="two circle circle-six circle-top-left"></span>
      <span class="three circle circle-five"></span>
      <span class="four circle circle-three circle-top-left"></span>
      <span class="five circle circle-six circle-bottom-left"></span>
      <span class="six circle circle-four circle-top-right"></span>
      <span class="seven circle circle-six circle-top-left"></span>
      <span class="eight circle circle-three"></span>
      <span class="nine circle circle-five circle-bottom-right"></span>
      <span class="ten circle circle-two circle-top-right"></span>
    </div>
  </div>
</div>
