<mat-toolbar color="primary" class="mat-elevation-z3">
  <mat-toolbar-row>
    <button mat-icon-button [routerLink]="['']">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span style="padding-left: 40px; font-size: 20px">{{ title.getTitle() }}</span>
  </mat-toolbar-row>
</mat-toolbar>
<mat-progress-bar *ngIf="saving" mode="indeterminate"></mat-progress-bar>
<div class="container">
  <mat-stepper orientation="vertical" linear #stepper (selectionChange)="traceStep($event)">
    <mat-step [stepControl]="user" label="Lietotājs">
      <p>Lūdzu, izvēlieties vienu no sekojošiem autorizācijas veidiem.</p>
      <form [formGroup]="user" fxLayout="column">
        <mat-radio-group formControlName="type">
          <div>
            <mat-radio-button value="oauth" class="auth-type">Vēlos autorizēties ar Google</mat-radio-button>
            <div
              fxLayout="column"
              class="form-auth"
              [@showAuthType]="this.user.value.type === 'oauth' ? 'show' : 'hide'"
            >
              <p>Izvēlieties šo veidu, ja jums jau ir izveidots Google lietotāja konts.</p>
              <ng-container *ngIf="authUser; else registerWithGoogleTpl">
                <div *ngIf="show" style="display: flex; flex-wrap: wrap; margin-bottom: 10px">
                  Autorizācija ar Google kontu&nbsp;<strong>{{ authUser.email }}</strong>
                </div>
                <button class="button margin" type="button" mat-raised-button (click)="registerWithGoogle()">
                  <mat-icon svgIcon="google" class="sign-in-icon"></mat-icon>Autorizēties ar citu Google kontu
                </button>
              </ng-container>
              <ng-template #registerWithGoogleTpl>
                <button class="button margin" type="button" mat-raised-button (click)="registerWithGoogle()">
                  <mat-icon svgIcon="google" class="sign-in-icon"></mat-icon>Autorizēties ar Google kontu
                </button>
              </ng-template>
              <mat-error *ngIf="errMsg('user.hasFirebaseUser') as err">{{ err }}</mat-error>
            </div>
          </div>
          <div>
            <mat-radio-button value="user" class="auth-type">Vēlos autorizēties ar paroli</mat-radio-button>
            <div
              fxLayout="column"
              class="form-auth margin"
              [@showAuthType]="this.user.value.type === 'user' ? 'show' : 'hide'"
            >
              <mat-form-field appearance="outline">
                <mat-label>E-pasta adrese</mat-label>
                <input type="email" autocomplete="email" formControlName="email" matInput />
                <mat-icon matIconSuffix>email</mat-icon>
                <mat-error *ngIf="errMsg('user.email') as err">{{ err }}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Parole</mat-label>
                <input type="password" autocomplete="new-password" formControlName="password" matInput />
                <mat-icon matIconSuffix>visibility</mat-icon>
                <mat-error *ngIf="errMsg('user.password') as err">{{ err }}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Parole (atkārtoti)</mat-label>
                <input type="password" autocomplete="new-password" formControlName="confirmPassword" matInput />
                <mat-icon matIconSuffix>visibility</mat-icon>
                <mat-error *ngIf="errMsg('user.confirmPassword') as err">{{ err }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-radio-group>
        <div class="margin">
          <mat-slide-toggle formControlName="agree"></mat-slide-toggle> Es piekrītu
          <span (click)="openTerms('agree')" class="rules"><em>personas datu izmantošanas</em></span>
          noteikumiem.
          <mat-error *ngIf="errMsg('user.agree') as err">{{ err }}</mat-error>
        </div>
        <div>
          <button class="button" mat-raised-button color="primary" (click)="nextStepPerson()">Turpināt</button>&nbsp;
          <button type="button" class="button" mat-button [routerLink]="['']">Atpakaļ</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="person" label="Persona">
      <p>Lūdzu, verificējiet savu identitāti tiešsaistē, izmantojot Smart-ID vai eParaksts.</p>
      <form [formGroup]="form" class="padding">
        <mat-slide-toggle formControlName="electronicIdentificationAgree"></mat-slide-toggle>Es piekrītu
        <span (click)="openTerms('electronicIdentificationAgree')" class="rules"
          ><em>elektroniskas identifikācijas risinājuma lietošanas noteikumiem</em></span
        >
        <mat-error *ngIf="errMsg('electronicIdentificationAgree') as err">{{ err }}</mat-error>
      </form>
      <div fxLayoutGap="10px" class="padding">
        <button mat-raised-button (click)="openSmartIDDialog()">
          <img src="/assets/images/sign-up/smart-id-logo.svg" alt="Smart-ID logo" style="height: 30px" />
        </button>
        <button mat-raised-button (click)="openPopUp()">
          <img src="/assets/images/sign-up/eparaksts-logo.svg" alt="eParaksts logo" style="height: 20px" />
        </button>
      </div>
      <p class="text">
        Gadījumā, ja neizmantojat Smart-ID vai eParaksts, lūdzu, ievadiet savus personas datus zemāk un ierodieties
        Saules aptiekā, lai uzrādītu savu personas apliecinošu dokumentu un pabeigtu identitātes pārbaudi.
      </p>
      <form [formGroup]="person" fxLayout="column">
        <mat-form-field appearance="outline">
          <mat-label>Vārds</mat-label>
          <input
            autocomplete="given-name"
            matInput
            formControlName="firstName"
            required
            [readonly]="personIdentity()"
          />
          <mat-error *ngIf="errMsg('person.firstName') as err">{{ err }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Uzvārds</mat-label>
          <input
            autocomplete="family-name"
            matInput
            formControlName="lastName"
            required
            [readonly]="personIdentity()"
          />
          <mat-error *ngIf="errMsg('person.lastName') as err">{{ err }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Personas kods</mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="identityNumber"
            mask="000000-00000"
            required
            [readonly]="personIdentity()"
            [dropSpecialCharacters]="false"
          />
          <mat-error *ngIf="errMsg('person.identityNumber') as err">{{ err }}</mat-error>
        </mat-form-field>
        <div>
          <button class="button" mat-raised-button color="primary" (click)="nextStepContactInfo()">Turpināt</button
          >&nbsp;
          <button class="button" mat-button matStepperPrevious>Atpakaļ</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="contactInfo" label="Kontakttālrunis">
      <p class="text">
        Jūsu tālruņa numurs tiks izmantots tikai un vienīgi pasūtījuma apstrādei un netiks izmantots citiem mērķiem.
        Farmaceits katru pasūtījumu sagatavos individuāli un sazināsies ar jums, lai labāk izprastu jūsu vajadzības un
        pielāgotu pasūtījumu, kā arī sniegtu konsultācijas par izrakstītajiem medikamentiem. Tālruņa numurs netiks
        izmantots nevienā citā darbībā un netiks nodots trešajām personām.
      </p>
      <form [formGroup]="contactInfo" fxLayout="column">
        <mat-form-field appearance="outline">
          <mat-label>Kontakttālrunis</mat-label>
          <input
            type="tel"
            autocomplete="tel-national"
            matInput
            formControlName="phoneNumber"
            prefix="+371 "
            mask="00 000 000"
            required
          />
          <mat-icon matIconSuffix>phone</mat-icon>
          <mat-error *ngIf="errMsg('contactInfo.phoneNumber') as err">{{ err }}</mat-error>
        </mat-form-field>
        <div>
          <button class="button" mat-raised-button color="primary" (click)="nextStepAddresses()">Turpināt</button>&nbsp;
          <button class="button" mat-button matStepperPrevious>Atpakaļ</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="addresses" label="Piegādes adreses">
      <p>Lūdzu, pievienojiet vismaz vienu adresi, kur vēlaties saņemt pasūtītos medikamentus.</p>
      <mat-list>
        <ng-container *ngFor="let address of addresses.value; index as i">
          <mat-list-item>
            <mat-icon style="align-self: center; color: inherit" matListItemIcon>home</mat-icon>
            <div style="display: flex; justify-content: space-between">
              <div>
                <span class="line" matListItemLine>{{ address.addressLine1 }}</span>
                <span class="line" matListItemLine>{{ address.city }}, {{ address.postCode }}</span>
              </div>
              <button class="line" mat-icon-button (click)="removeAddressAt(i)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </mat-list-item>
          <mat-divider *ngIf="i + 1 < addresses.value.length"></mat-divider>
        </ng-container>
      </mat-list>
      <button
        class="button big"
        type="button"
        mat-raised-button
        color="primary"
        [class.hidden]="addresses.length >= 2"
        (click)="openAddAddressDialog()"
      >
        Pievienot adresi
      </button>
      <mat-error *ngIf="errMsg('addresses') as err">{{ err }}</mat-error>
      <div class="button-wrapper">
        <button class="button" mat-raised-button color="primary" [disabled]="saving" (click)="complete()">
          Turpināt
        </button>
        &nbsp;
        <button class="button" [disabled]="saving" mat-button matStepperPrevious>Atpakaļ</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
