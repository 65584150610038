import { animate, state, style, transition, trigger } from '@angular/animations'
import { StepperSelectionEvent } from '@angular/cdk/stepper'
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  Auth,
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getIdTokenResult,
  sendEmailVerification,
  signInWithPopup,
} from '@angular/fire/auth'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatStepper } from '@angular/material/stepper'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Span, Transaction } from '@elastic/apm-rum'
import { ApmService } from '@elastic/apm-rum-angular'
import jwt_decode from 'jwt-decode'
import { Subscription, forkJoin, from, fromEvent } from 'rxjs'
import { filter, first, switchMap, tap } from 'rxjs/operators'
import { featureToggle } from 'src/app/featureToggle'
import { deepTrim } from 'src/app/filters'
import { Address, Client, DefaultService, Identity, Person } from 'src/app/services/api'
import { SnackBarService } from 'src/app/services/snackbar.service'
import { environment } from 'src/environments/environment'
import { equalValidator } from '../../validators'
import { AddressComponent, AddressForm } from '../address/address.component'
import { ElectronicIdentificationTermsComponent } from '../electronic-identification-terms/electronic-identification-terms.component'
import { SmartIDDialogComponent } from '../smart-id-dialog/smart-id-dialog.component'
import { UseOfPersonalDataComponent } from '../use-of-personal-data/use-of-personal-data.component'

type AuthType = 'oauth' | 'user'

const steps = ['User step', 'Identity step', 'Phone numbers step', 'Addresses step', 'Membership step']

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [
    trigger('showAuthType', [
      state('show', style({ height: '*' })),
      state(
        'hide',
        style({
          height: '0px',
        })
      ),
      transition('show => hide', [animate('0.25s ease-out')]),
      transition('hide => show', [animate('0.25s ease-in')]),
    ]),
  ],
})
export class SignUpComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper!: MatStepper

  readonly featureToggle = featureToggle.deleteFirebaseAccount

  readonly form = this.fb.nonNullable.group({
    user: this.fb.nonNullable.group({
      type: ['oauth' as AuthType],
      hasFirebaseUser: [false, Validators.requiredTrue],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, equalValidator('user.password')]],
      agree: [false, Validators.requiredTrue],
    }),
    electronicIdentificationAgree: this.fb.nonNullable.control(false, Validators.requiredTrue),
    person: this.fb.nonNullable.group({
      firstName: ['', [Validators.required, Validators.min(2)]],
      lastName: ['', [Validators.required, Validators.min(2)]],
      identityNumber: ['', [Validators.required]],
    }),
    contactInfo: this.fb.nonNullable.group({
      phoneNumber: ['', Validators.required],
    }),
    addresses: this.fb.nonNullable.array<FormGroup<AddressForm>>([], Validators.required),
  })

  page: 'registration' | 'success' = 'registration'

  subscription = new Subscription()

  showAddress = false

  saving = false

  authUser: User | null = null

  show = false

  identity: Identity | undefined

  eParakstsDone?: Subscription = undefined

  transaction?: Transaction

  transactionOutcome: 'success' | 'failure' = 'failure'

  identityVerifiedBy: 'Smart-ID' | 'eParaksts' | 'unverified' = 'unverified'

  span?: Span

  constructor(
    private apm: ApmService,
    private api: DefaultService,
    private auth: Auth,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private snackBarService: SnackBarService,
    private snackBar: MatSnackBar,
    public title: Title
  ) {}

  get user() {
    return this.form.controls.user
  }

  get person() {
    return this.form.controls.person
  }

  get contactInfo() {
    return this.form.controls.contactInfo
  }

  get addresses() {
    return this.form.controls.addresses
  }

  @HostListener('window:beforeunload', ['$event'])
  private endTransaction(): void {
    this.span?.end()

    // TODO(jhorsts): set transaction outcome/status instead. I cannot find a way of doing it.
    this.transaction?.addLabels({
      outcome: this.transactionOutcome,
    })

    this.transaction?.end()
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(): void {
    if (this.featureToggle) {
      this.authUser?.getIdTokenResult().then((token) => {
        if (!token.claims.role) {
          this.authUser?.delete()
        }
      })
    }
  }

  ngOnInit(): void {
    this.transaction = this.apm.apm.startTransaction('Sign up', 'custom')
    this.span = this.transaction?.startSpan(steps[0], 'custom')

    this.title.setTitle('Reģistrācija')

    this.setUserValidators('oauth')
    this.subscription.add(this.user.controls.type.valueChanges.subscribe((v) => this.setUserValidators(v)))

    this.subscription.add(
      this.form.controls.user.controls.type.valueChanges
        .pipe(
          filter(() => this.featureToggle),
          filter((type) => type === 'user' && !!this.authUser),
          switchMap(() => getIdTokenResult(this.authUser!)),
          filter((token) => !token.claims.role)
        )
        .subscribe({
          next: () => {
            this.authUser?.delete()
            this.authUser = null
          },
        })
    )
  }

  ngOnDestroy() {
    if (this.featureToggle) {
      this.authUser?.getIdTokenResult().then((token) => {
        if (!token.claims.role) {
          this.authUser?.delete()
        }
      })
    }

    this.subscription.unsubscribe()
    this.endTransaction()
  }

  setUserValidators(type: AuthType) {
    const { email, password, confirmPassword, hasFirebaseUser } = this.user.controls

    switch (type) {
      case 'oauth':
        hasFirebaseUser.enable()
        email.disable()
        password.disable()
        confirmPassword.disable()
        break
      case 'user':
        email.enable()
        password.enable()
        confirmPassword.enable()
        hasFirebaseUser.disable()
        break
    }

    this.user.updateValueAndValidity()
  }

  errMsg(name: string): string {
    const ctrl = this.form.get(name)!

    if (ctrl.untouched) {
      return ''
    }

    if (ctrl.hasError('required')) {
      switch (name) {
        case 'user.hasFirebaseUser':
          return 'Autorizācija ar google kontu ir nepieciešama'
        case 'user.email':
          return 'E-pasta adrese ir nepieciešama'
        case 'user.password':
          return 'Parole ir nepieciešama'
        case 'user.confirmPassword':
          return 'Parole (atkārtoti) ir nepieciešama'
        case 'user.agree':
          return 'Piekrišana ir nepieciešama'
        case 'electronicIdentificationAgree':
          return 'Piekrišana ir nepieciešama'
        case 'person.firstName':
          return 'Vārds ir nepieciešams'
        case 'person.lastName':
          return 'Uzvārds ir nepieciešams'
        case 'person.identityNumber':
          return 'Personas kods ir nepieciešams'
        case 'contactInfo.phoneNumber':
          return 'Kontakttālrunis ir nepieciešams'
        case 'addresses':
          return 'Pievienojiet vismaz vienu piegādes adresi'
      }
    }

    if (ctrl.hasError('equal') && name === 'user.confirmPassword') {
      return 'Parole (atkārtoti) nav līdzīga parolei'
    }

    if (ctrl.hasError('mask')) {
      switch (name) {
        case 'person.identityNumber':
          return 'Nepareizs personas kods'
        case 'contactInfo.phoneNumber':
          return 'Nepareizs telefona numurs'
        case 'clientCard.card':
          return 'Nepareizs klienta kartes numurs'
      }
    }

    if (ctrl.hasError('minlength')) {
      switch (name) {
        case 'user.password':
          return 'Paroles garumam jābūt vismaz 6 simboliem'
      }
    }

    if (ctrl.hasError('card')) {
      return 'Nepareizs klienta kartes numurs'
    }

    return ''
  }

  removeAddressAt(i: number) {
    this.form.controls.addresses.removeAt(i)
  }

  async registerWithGoogle() {
    const userCredentials = await signInWithPopup(this.auth, new GoogleAuthProvider())
    this.authUser = userCredentials.user

    const { user } = userCredentials

    const token = await user.getIdTokenResult()

    if (token.claims.role) {
      this.show = false
      if (token.claims.role === 'consumer') {
        this.snackBar
          .open('Lietotājs jau ir reģistrēts. Vai vēlaties autorizēties ar doto lietotāju?', 'Autorizēties', {
            duration: 5000,
          })
          .onAction()
          .subscribe(() => this.router.navigate(['orders']))
        return
      }

      this.snackBarService.open(
        `Lietotājs ar ${this.authUser.email} jau ir reģistrēts ar ${token.claims.role} lomu. Lūdzu, reģistrējieties ar citu lietotāju!`
      )
      return
    }

    this.user.controls.hasFirebaseUser.reset(!!user)

    if (!user) {
      return
    }

    this.show = true

    if (!this.identity) {
      let firstName = ''
      let lastName = ''

      if (user.displayName) {
        const [a, ...b] = user.displayName.split(' ')
        firstName = a
        lastName = b.join(' ')
      }

      this.person.controls.firstName.reset(firstName)
      this.person.controls.lastName.reset(lastName)
    }

    if (user.phoneNumber) {
      this.contactInfo.controls.phoneNumber.reset(user.phoneNumber)
    }
  }

  nextStepPerson() {
    this.user.markAllAsTouched()

    if (this.user.invalid) {
      return
    }

    const { email, type } = this.user.getRawValue()

    if (type === 'oauth') {
      if (this.user.controls.hasFirebaseUser.value) {
        this.stepper.next()
      }

      return
    }

    this.auth.signOut()

    // verify user does not exist
    from(fetchSignInMethodsForEmail(this.auth, email)).subscribe({
      next: (v) => {
        if (v.length > 0) {
          this.snackBarService.error('Lietotājs ar doto e-pasta adresi jau ir reģistrēts')
        } else {
          this.stepper.next()
        }
      },
      error: (err) => this.snackBarService.httpError(err),
    })
  }

  nextStepContactInfo() {
    this.person.markAllAsTouched()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.transaction?.addLabels({ identity_verified_by: this.identityVerifiedBy })
    this.stepper.next()
  }

  nextStepAddresses() {
    this.contactInfo.markAllAsTouched()
    this.stepper.next()
  }

  // fromForm transforms form value into persistable value
  fromForm(): {
    user: { email: string; password: string; type: AuthType }
    client: Client
    addresses: Address[]
  } {
    const { user, person, addresses: formAddresses, contactInfo } = deepTrim(this.form.getRawValue())

    const client: Client = person

    client.phones = [
      {
        type: contactInfo.phoneNumber.startsWith('2') ? 'mobile' : 'home',
        number: `+371${contactInfo.phoneNumber}`,
      },
    ]

    client.email = user.type === 'user' ? user.email : this.authUser?.email ?? ''

    const addresses = formAddresses.map((v) => ({
      ...v,
      countryCode: 'LV',
    }))

    return { user, client, addresses }
  }

  complete() {
    this.addresses.markAllAsTouched()

    this.form.updateValueAndValidity()

    this.saving = true

    const { user, client, addresses } = this.fromForm()

    from(
      user.type === 'user' ? createUserWithEmailAndPassword(this.auth, user.email, user.password) : Promise.resolve()
    )
      .pipe(
        switchMap(() => this.api.createClient(client, this.identity?.signed)),
        switchMap(() => getIdTokenResult(this.auth.currentUser!, true)),
        // global access token is not set in api client yet, set it manually
        tap((token) => (this.api.configuration.accessToken = token.token)),
        switchMap((token) => {
          const clientId = +(token.claims.clientId as string)

          return forkJoin([...addresses.map((v) => this.api.createClientAddress(clientId, v))])
        })
      )
      .subscribe({
        next: () => {
          this.transactionOutcome = 'success'

          if (user.type === 'user') {
            sendEmailVerification(this.auth.currentUser!)
          }

          this.router.navigate([this.authUser?.emailVerified ? 'sign-up/done' : 'sign-up/unverified'], {
            replaceUrl: true,
          })
        },
        error: (err) => {
          if (this.featureToggle) {
            this.auth.currentUser?.getIdTokenResult().then((token) => {
              if (!token.claims.role) {
                this.auth.currentUser?.delete()
              }
            })
          }

          this.saving = false

          if (err.message) {
            this.snackBarService.error(err.message)
          } else {
            this.snackBarService.httpError(err)
          }
        },
      })
  }

  openAddAddressDialog() {
    this.dialog
      .open(AddressComponent, { autoFocus: false, width: '700px' })
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe({
        next: (v) => this.addresses.push(v),
      })
  }

  openPopUp(): void {
    this.form.controls.electronicIdentificationAgree.markAsTouched()

    if (this.form.controls.electronicIdentificationAgree.invalid) {
      return
    }

    if (this.eParakstsDone) {
      this.eParakstsDone.unsubscribe()
    }

    const eParakstsState = crypto.randomUUID()

    const redirectUrl = `${document.location.origin}/sign-up`

    const params = new URLSearchParams()
    params.append('response_type', 'code')
    params.append('client_id', environment.eParaksts.clientId)
    params.append('redirect_uri', redirectUrl)
    params.append('state', eParakstsState)
    params.append('scope', 'urn:lvrtc:fpeil:aa')
    params.append('ui_locales', 'lv')
    params.append('prompt', 'login')

    const width = 900
    const height = 700
    const top = (screen.height - height) / 4
    const left = (screen.width - width) / 2

    window.open(
      `${environment.eParaksts.url}?${params}`,
      'e-paraksts',
      `width=${width},height=${height},top=${top},left=${left}`
    )

    this.eParakstsDone = fromEvent<MessageEvent<{ code: string; state: string; error: string }>>(window, 'message')
      .pipe(
        first(),
        filter((v) => {
          if (v.data.state !== eParakstsState) {
            this.snackBarService.error('Kļūda autentifikācijā')
            console.error('The sent state does not match the received state', v.data.state, eParakstsState)
            return false
          }

          if (v.data.error) {
            this.snackBarService.error('Kļūda autentifikācijā')
            return false
          }

          return true
        }),
        switchMap((v) => this.api.getEparakstsIdentity(v.data.code, redirectUrl))
      )
      .subscribe({
        next: (identity) => {
          this.identity = identity
          this.identityVerifiedBy = 'eParaksts'

          try {
            const identityData = jwt_decode<Person>(identity.signed)

            this.person.reset(identityData)
          } catch (err) {
            throw new Error(`Cannot decode signed identity: ${identity.signed}`, { cause: err })
          }
        },
        error: (err) => this.snackBarService.httpError(err),
      })

    this.subscription.add(this.eParakstsDone)
  }

  personIdentity(): boolean {
    return typeof this.identity === 'object'
  }

  openTerms(value: string): void {
    const dialog = this.dialog.open<
      UseOfPersonalDataComponent | ElectronicIdentificationTermsComponent,
      MatDialogConfig,
      true | undefined
    >(value === 'agree' ? UseOfPersonalDataComponent : ElectronicIdentificationTermsComponent, {
      autoFocus: false,
      maxHeight: '90vh',
    })

    dialog.componentInstance.openedAsDialog = true

    dialog
      .afterClosed()
      .pipe(filter((v): v is true => !!v))
      .subscribe({
        next: (v) =>
          value === 'agree'
            ? this.user.controls.agree.setValue(v)
            : this.form.controls.electronicIdentificationAgree.setValue(v),
      })
  }

  openSmartIDDialog(): void {
    this.form.controls.electronicIdentificationAgree.markAsTouched()

    if (this.form.controls.electronicIdentificationAgree.invalid) {
      return
    }

    this.dialog
      .open(SmartIDDialogComponent, { width: '500px' })
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe({
        next: (identity: Identity) => {
          this.identity = identity
          this.identityVerifiedBy = 'Smart-ID'

          try {
            const identityData = jwt_decode<Person>(identity.signed)

            this.person.reset(identityData)
          } catch (err) {
            throw new Error(`Cannot decode signed identity: ${identity.signed}`, { cause: err })
          }
        },
      })
  }

  traceStep(v: StepperSelectionEvent): void {
    this.span?.end()
    this.span = this.transaction?.startSpan(steps[v.selectedIndex], 'custom')
  }
}
