<mat-toolbar color="primary" class="mat-elevation-z3" *ngIf="!openedAsDialog">
  <button mat-icon-button (click)="goBack()">
    <mat-icon>close</mat-icon>
  </button>
  <span class="title">{{ title.getTitle() }}</span>
</mat-toolbar>
<div class="container text" [class.dialog]="openedAsDialog">
  <h3>Elektroniskās identifikācijas risinājuma lietošanas noteikumi</h3>
  <p>
    1. Aptieka pakalpojumu sniegšanai klientiem, izmanto elektroniskās identifikācijas risinājums, kas darbojas
    atbilstoši Fizisko personu elektroniskās identifikācijas likuma prasībām, ievērojot Vispārīgās datu aizsardzības
    regulas (ES) 2016/679 (VDAR) nosacījumus.
  </p>
  <p>
    2. Nepiekrītot šiem noteikumiem un datu nodošanai elektronisko pakalpojumu sniedzējam, Jūs nevarēsiet izmantot
    elektroniskās identifikācijas risinājumu un tos elektroniskos pakalpojumus, kur identifikācija ir obligāta.
  </p>
  <p>
    3. Lietotāja personas datu (vārda, uzvārda, personas koda) apmaiņu starp SIA Saules aptieku un elektroniskās
    identifikācijas servisa sniedzēju tiek nodrošināta atbilstoši normatīo aktu prasībām un starp šīm pusēm noslēgtās
    vienošanās.
  </p>
  <p>4. Elektroniskās identifikācijas process:</p>
  <p>
    4.1. Jūs apstipriniet, ka esiet iepazinies ar šiem noteikumiem un personas datu apstrādes mērķi, un atļaujiet SIA
    Saules aptieka (<strong>Aptieka</strong>) saņemt Jūsu datus (vārdu, uzvārdu un personas kodu), lai tos automātiski
    nodotu elektroniskā pakalpojuma sniedzējam;
  </p>
  <p>4.2. Jūs izvēlieties elektroniskās identifikācijas servisa sniedzēju —Smart-ID vai eParakstu;</p>
  <p>
    4.3. Izvēlētajā elektroniskās identifikācijas servisa sniedzēja tīmekļa lapā veiciet identifikācijas procesu,
    prasītajos laukos ievadot attiecīgā servisa sniedzēja prasīto informāciju, kas ļaus Jūs identificēt;
  </p>
  <p>
    4.4. Veiksmīgas elektroniskās identifikācijas rezultātā, identifikācijas servisa sniedzējs Jūsu personas datus nodod
    <strong>Aptiekai</strong>;
  </p>
  <p>
    4.5. <strong>Aptieka</strong> saņemtos lietotāja personas datus izmanto Jūsu izvēlētā pakalpojuma nodrošināšanai;
  </p>
  <p>
    4.6. Lai izvairītos no nesankcionētas Jūsu identitātes izmantošanas apdraudējuma, pēc atslēgšanās no
    manasauleaptieka.lv, kurai piekļuvāt ar identifikācijas risinājuma palīdzību, aizveriet tīmekļa pārlūkprogrammu
    (visus atvērtos pārlūkprogrammas logus).
  </p>
  <mat-dialog-actions align="end" *ngIf="openedAsDialog">
    <button mat-button mat-dialog-close>Atcelt</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="true">Piekrītu</button>
  </mat-dialog-actions>
</div>
