import { Component } from '@angular/core'
import {
  Auth,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from '@angular/fire/auth'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { SnackBarService } from 'src/app/services/snackbar.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { User } from 'firebase/auth'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  readonly form = this.fb.nonNullable.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required],
  })

  readonly resetPasswordEmail = this.fb.nonNullable.control('', [Validators.email, Validators.required])

  page: 'login' | 'reset-password' = 'login'

  authUser: User | null = null

  constructor(
    public router: Router,
    private auth: Auth,
    public dialogRef: MatDialogRef<SignInComponent>,
    private snackBarService: SnackBarService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  signInWithEmailAndPassword(): void {
    this.form.markAllAsTouched()

    if (this.form.invalid) {
      return
    }

    const { email, password } = this.form.getRawValue()

    this.signInHandler(signInWithEmailAndPassword(this.auth, email, password))
  }

  signInWithGoogle(): void {
    this.signInHandler(signInWithPopup(this.auth, new GoogleAuthProvider()))
  }

  signInHandler(userCredential: Promise<UserCredential>) {
    userCredential
      .then((v) => v.user?.getIdTokenResult())
      .then(
        (token) => {
          const role = token?.claims.role
          if (typeof role !== 'string') {
            this.snackBar
              .open('Lietotājs nav reģistrēts. Vēlaties uzsākt reģistrāciju?', 'Reģistrēties', {
                duration: 5000,
              })
              .onAction()
              .subscribe(() => {
                this.dialogRef.close()
                this.router.navigate(['sign-up'])
              })

            return
          }

          if (role !== 'consumer') {
            this.snackBarService.open(
              `Lietotājs ir reģistrēts ar ${role} lomu. Nepieciešams autorizēties ar citu e-pastu.`
            )
            return
          }

          if (!this.auth.currentUser?.emailVerified) {
            this.dialogRef.close()
            this.router.navigate(['sign-up/unverified'])
            return
          }

          this.dialogRef.close()

          this.router.navigate([sessionStorage.getItem('afterLogin') ?? 'orders'])
        },
        (err) => this.snackBarService.error(this.messages(err.code) || err.message)
      )
  }

  sendPasswordResetEmail(): void {
    sendPasswordResetEmail(this.auth, this.resetPasswordEmail.value).then(
      () => {
        this.snackBar.open('Paroles nomaiņas saite ir izsūtīta.')
        this.page = 'login'
      },
      (err) => this.snackBarService.error(this.messages(err.code) || err.message)
    )
  }

  showPasswordReset(): void {
    this.resetPasswordEmail.reset(this.form.value.email)
    this.page = 'reset-password'
  }

  messages(err: string): string {
    switch (err) {
      // The popup has been closed by the user before finalizing the operation.
      case 'auth/popup-closed-by-user':
        return 'Uznirstošais logs aizvērts, nepabeidzot operāciju.'
      // Access to this account has been temporarily disabled due to many failed login attempts.
      // You can immediately restore it by resetting your password or you can try again later.
      case 'auth/too-many-requests':
        return `Īslaicīgi bloķēts lietotājs. Mēģiniet pieslēgties vēlāk vai nomaini paroli, lai atgūtu pieeju.`
      // The password is invalid or the user does not have a password.
      case 'auth/wrong-password':
        return 'Nepareiza parole vai lietotājam nav paroles.'
      // There is no user record corresponding to this identifier. The user may have been deleted.
      case 'auth/user-not-found':
        return 'Lietotājs neeksistē vai ir izdzēsts.'
      default:
        return err
    }
  }
  // TODO: create backend service to translate (and cache) English messages to Latvian using google translate API
}
