export const environment = {
  production: false,
  apiUrl: 'https://staging.api.saulesaptieka.lv/v1',
  apm: {
    environment: 'staging',
    serviceName: 'mana',
    serverUrl: 'https://apm.expect.digital',
    distributedTracingOrigins: ['https://staging.api.saulesaptieka.lv'],
  },
  firebase: {
    apiKey: 'AIzaSyDsXEA0AaEFdgIgYaggIoRHlHHxRLUoC5Y',
    authDomain: 'saulesaptieka-dev.firebaseapp.com',
    projectId: 'saulesaptieka-dev',
    storageBucket: 'saulesaptieka-dev.appspot.com',
    messagingSenderId: '112633426923',
    appId: '1:112633426923:web:9afbd826220f17384b9fae',
    measurementId: 'G-NTSZKBQC4Z',
  },
  eParaksts: {
    url: 'https://eidas-demo.eparaksts.lv/trustedx-authserver/oauth/lvrtc-eipsign-as',
    clientId: '98364324546993823782',
  },
}
