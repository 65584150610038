import { Component, Input } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'app-use-of-personal-data',
  templateUrl: './use-of-personal-data.component.html',
  styleUrls: ['./use-of-personal-data.component.scss'],
})
export class UseOfPersonalDataComponent {
  @Input() openedAsDialog = false

  constructor(
    public title: Title,
    private router: Router
  ) {}

  goBack() {
    this.router.navigate([sessionStorage.history])
    sessionStorage.removeItem('history')
  }
}
