import { Component, Input } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { featureToggle } from 'src/app/featureToggle'

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  @Input() openedAsDialog = false

  readonly featureToggle = featureToggle.betterPayment

  constructor(
    public title: Title,
    private router: Router
  ) {}

  goBack() {
    this.router.navigate([sessionStorage.history])
    sessionStorage.removeItem('history')
  }
}
