import { Component, Inject } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-new-phone',
  templateUrl: './new-phone.component.html',
  styleUrls: ['./new-phone.component.scss'],
})
export class NewPhoneComponent {
  readonly form = this.fb.nonNullable.group({
    number: [this.data || ''],
  })

  edit = false

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  save(): void {
    this.form.markAllAsTouched()

    if (this.form.invalid) {
      return
    }
    const { number } = this.form.getRawValue()
    this.dialogRef.close(number)
  }
}
