<mat-dialog-content class="dialog__content">
  <span class="dialog__text">
    Lūdzu, ievadiet pasta indeksu un izvēlieties ielas nosaukumu. Aizpildiet pārējo informāciju: mājas nr., dzīvokļa
    nr., durvju kods, un tad spiediet {{ edit ? 'Labot adresi' : 'Pievienot adresi' }}.
  </span>
  <div [@showAddress]="showAddress ? 'show' : 'hide'" class="wrapper">
    <form [formGroup]="address" class="dialog__form">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Iela, mājas nr./nosaukums, dzīvokļa nr., durvju kods</mat-label>
        <input autocomplete="street-address" matInput formControlName="addressLine1" required #addressInput />
        <mat-error *ngIf="errMsg(address.controls.addressLine1) as err">{{ err }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Pilsēta, ciems, pagasts, reģions</mat-label>
        <input matInput formControlName="city" required />
        <mat-error *ngIf="errMsg(address.controls.city) as err">{{ err }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Pasta indekss</mat-label>
        <input type="hidden" [matAutocomplete]="addressesSuggestionsAuto" />
        <input
          matInput
          formControlName="postCode"
          required
          prefix="LV-"
          mask="0000"
          autocomplete="off"
          #postCodeInput
        />
        <mat-error *ngIf="errMsg(address.controls.postCode) as err">{{ err }}</mat-error>
      </mat-form-field>
      <mat-autocomplete
        #addressesSuggestionsAuto="matAutocomplete"
        autoActiveFirstOption
        (optionSelected)="selectAddressSuggestion($event.option.value)"
        [displayWith]="displayPostCode"
      >
        <mat-option *ngFor="let v of addressesSuggestions | async as list; index as i" [value]="v">
          <strong *ngIf="i + 1 === list.length; else addressFound">
            {{ addressSuggestion({ city: 'Nevaru atrast adresi, ievadīšu pati/-s', postCode: '' }) }}
          </strong>
          <ng-template #addressFound>{{ addressSuggestion(v) }}</ng-template>
        </mat-option>
      </mat-autocomplete>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog__actions">
  <button mat-button class="button" mat-dialog-close>Atcelt</button>
  <button class="button" mat-raised-button color="primary" (click)="addAddress()">
    {{ edit ? 'Labot adresi' : 'Pievienot adresi' }}
  </button>
</mat-dialog-actions>
