import { NgModule } from '@angular/core'
import { AuthGuard, canActivate, customClaims } from '@angular/fire/auth-guard'
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router'
import { pipe } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { ContactsComponent } from './components/contacts/contacts.component'
import { DeliveryConditionsComponent } from './components/delivery-conditions/delivery-conditions.component'
import { ElectronicIdentificationTermsComponent } from './components/electronic-identification-terms/electronic-identification-terms.component'
import { HomeComponent } from './components/home/home.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { OrderComponent } from './components/order/order.component'
import { OrdersNewComponent } from './components/orders-new/orders-new.component'
import { OrdersComponent } from './components/orders/orders.component'
import { PersonIdentityComponent } from './components/person-identity/person-identity.component'
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component'
import { SignUpDoneComponent } from './components/sign-up-done/sign-up-done.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'
import { UnverifiedUserComponent } from './components/unverified-user/unverified-user.component'
import { UseOfPersonalDataComponent } from './components/use-of-personal-data/use-of-personal-data.component'

const consumerOnly = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  pipe(
    customClaims,
    tap((claims) => {
      if (claims.role !== 'consumer' && state.url.includes('/orders/')) {
        sessionStorage.setItem('afterLogin', state.url)
      }
    }),
    map((claims) => (claims.role === 'consumer' && typeof claims.clientId === 'number') || [''])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any // AuthPipeGenerator

// The page is running inside the pop-up when we receive "code" and "state"
// query parameters (we use eParaksts for identity verification).
// Take "code" and "state" query parameters and send them back to
// the main /sign-up window and close the pop-up window.
const postMessage = (route: ActivatedRouteSnapshot): boolean => {
  const { code, state, error } = route.queryParams
  if ((code || error) && state) {
    window.opener.postMessage({ code, state, error }, document.location.origin)
    window.close()
    return false
  }
  return true
}

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [postMessage],
  },
  {
    path: 'sign-up/unverified',
    component: UnverifiedUserComponent,
    ...canActivate(consumerOnly),
  },
  {
    path: 'sign-up/done',
    component: SignUpDoneComponent,
    ...canActivate(consumerOnly),
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard],
    data: { animation: 'OrdersList', authGuardPipe: consumerOnly },
  },
  {
    path: 'orders/:id',
    component: OrderComponent,
    canActivate: [AuthGuard],
    data: { animation: 'OrderPage', authGuardPipe: consumerOnly },
  },
  {
    path: 'orders-new',
    component: OrdersNewComponent,
    ...canActivate(consumerOnly),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    title: 'Privātuma politika',
  },
  {
    path: 'agreement',
    component: UseOfPersonalDataComponent,
    title: 'Piekrišana personas datu izmantošanai',
  },
  {
    path: 'identification-terms',
    component: ElectronicIdentificationTermsComponent,
    title: 'Elektroniskās identifikācijas risinājuma lietošanas noteikumi',
  },
  {
    path: 'delivery-terms',
    component: DeliveryConditionsComponent,
    title: 'Piegādes nosacījumi',
  },
  {
    path: 'profile',
    ...canActivate(consumerOnly),
    children: [
      {
        path: 'identity',
        component: PersonIdentityComponent,
        title: 'Personas identitāte',
        canActivate: [postMessage],
      },
      {
        path: 'contacts',
        component: ContactsComponent,
        title: 'Tālruņa numurs un adreses',
      },
    ],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
