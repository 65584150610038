/**
 * API
 * API. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * semi-verified - consumer has provided digital-only proof of identity (passport or id card), verified - consumer has provided physical proof of identity
 */
export type IdentityVerificationState = 'pending' | 'semi-verified' | 'verified';

export const IdentityVerificationState = {
    Pending: 'pending' as IdentityVerificationState,
    SemiVerified: 'semi-verified' as IdentityVerificationState,
    Verified: 'verified' as IdentityVerificationState
};

