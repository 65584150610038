<mat-dialog-content class="dialog">
  <div class="container__icon">
    <mat-icon color="primary" fontIcon="warning" class="icon"></mat-icon>
  </div>
  <div>
    <p>
      Jūsu konts ir reģistrēts ar <strong>Smart-ID Basic</strong> līmeni. Lai varētu verificēt savu identitāti, Jums
      nepieciešams <strong>kvalificētais Smart-ID</strong> konts.
    </p>
    <p>
      Uzziniet vairāk par to, kā
      <a
        href="https://www.smart-id.com/lv/palidziba/buj/smart-id-atjauninasana/jusu-smart-id-basic-konta-atjauninasana/"
        target="_blank"
        class="link"
        >atjaunināt savu Smart-ID Basic</a
      >
      kontu uz pilnas piekļuves Smart-ID.
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions" align="end">
  <button color="primary" mat-raised-button mat-dialog-close>Aizvērt</button>
</mat-dialog-actions>
