/**
 * API
 * API. 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * payment states taken from EveryPay APIv4 Integration Documentation (09.03.2022 revision)
 */
export type PaymentState = 'initial' | 'waiting_for_sca' | 'sent_for_processing' | 'waiting_for_3ds_response' | 'settled' | 'failed' | 'abandoned' | 'voided' | 'refunded' | 'chargebacked';

export const PaymentState = {
    Initial: 'initial' as PaymentState,
    WaitingForSca: 'waiting_for_sca' as PaymentState,
    SentForProcessing: 'sent_for_processing' as PaymentState,
    WaitingFor3dsResponse: 'waiting_for_3ds_response' as PaymentState,
    Settled: 'settled' as PaymentState,
    Failed: 'failed' as PaymentState,
    Abandoned: 'abandoned' as PaymentState,
    Voided: 'voided' as PaymentState,
    Refunded: 'refunded' as PaymentState,
    Chargebacked: 'chargebacked' as PaymentState
};

