import { Component, OnDestroy, OnInit } from '@angular/core'
import { Auth } from '@angular/fire/auth'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser'
import { getIdTokenResult } from 'firebase/auth'
import jwt_decode from 'jwt-decode'
import { Subscription, filter, first, from, fromEvent, map, shareReplay, switchMap, tap } from 'rxjs'
import { Client, DefaultService, Identity, Person } from 'src/app/services/api'
import { SnackBarService } from 'src/app/services/snackbar.service'
import { environment } from 'src/environments/environment'
import { ElectronicIdentificationTermsComponent } from '../electronic-identification-terms/electronic-identification-terms.component'
import { SmartIDDialogComponent } from '../smart-id-dialog/smart-id-dialog.component'

@Component({
  selector: 'app-person-identity',
  templateUrl: './person-identity.component.html',
  styleUrls: ['./person-identity.component.scss'],
})
export class PersonIdentityComponent implements OnInit, OnDestroy {
  readonly agreement = this.fb.nonNullable.group({
    electronicIdentificationAgree: this.fb.nonNullable.control(false, Validators.requiredTrue),
  })

  readonly person = this.fb.nonNullable.group({
    firstName: [{ value: '', disabled: true }, [Validators.required, Validators.min(2)]],
    lastName: [{ value: '', disabled: true }, [Validators.required, Validators.min(2)]],
    identityNumber: [{ value: '', disabled: true }, [Validators.required]],
  })

  identity: Identity | undefined

  loading = true

  page!: 'verified' | 'unverified'

  readonly clientId = from(getIdTokenResult(this.auth.currentUser!)).pipe(
    map((token) => +(token.claims.clientId as string)),
    shareReplay(1)
  )

  client!: Client

  eParakstsDone?: Subscription = undefined

  readonly subscription = new Subscription()

  constructor(
    private auth: Auth,
    private api: DefaultService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public title: Title,
    private snackBar: SnackBarService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.clientId
        .pipe(
          switchMap((clientId) => this.api.getClient(clientId)),
          tap(() => (this.loading = false))
        )
        .subscribe({
          next: (client) => {
            this.client = client

            this.person.reset({
              firstName: client.firstName,
              lastName: client.lastName,
              identityNumber: client.identityNumber,
            })
          },
          error: (err) => this.snackBar.httpError(err),
        })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  openPopUp(): void {
    this.agreement.markAsTouched()

    if (this.agreement.invalid) {
      return
    }

    if (this.eParakstsDone) {
      this.eParakstsDone.unsubscribe()
    }

    const eParakstsState = crypto.randomUUID()

    const redirectUrl = `${document.location.origin}/profile/identity`

    const params = new URLSearchParams()
    params.append('response_type', 'code')
    params.append('client_id', environment.eParaksts.clientId)
    params.append('redirect_uri', redirectUrl)
    params.append('state', eParakstsState)
    params.append('scope', 'urn:lvrtc:fpeil:aa')
    params.append('ui_locales', 'lv')
    params.append('prompt', 'login')

    const width = 900
    const height = 700
    const top = (screen.height - height) / 4
    const left = (screen.width - width) / 2

    window.open(
      `${environment.eParaksts.url}?${params}`,
      'eParaksts',
      `width=${width},height=${height},top=${top},left=${left}`
    )

    this.eParakstsDone = fromEvent<MessageEvent<{ code: string; state: string; error: string }>>(window, 'message')
      .pipe(
        first(),
        filter((v) => {
          if (v.data.state !== eParakstsState) {
            this.snackBar.error('Kļūda autentifikācijā')
            console.error('The sent state does not match the received state', v.data.state, eParakstsState)
            return false
          }

          if (v.data.error) {
            this.snackBar.error('Kļūda autentifikācijā')
            return false
          }

          return true
        }),
        switchMap((v) => this.api.getEparakstsIdentity(v.data.code, redirectUrl))
      )
      .subscribe({
        next: (identity) => {
          this.identity = identity
          try {
            const identityData = jwt_decode<Person>(identity.signed)

            this.patchClient(identity.signed)

            this.person.reset(identityData)
          } catch (err) {
            throw new Error(`Cannot decode signed identity: ${identity.signed}`, { cause: err })
          }
        },
        error: (err) => this.snackBar.httpError(err),
      })

    this.subscription.add(this.eParakstsDone)
  }

  openTerms(): void {
    const dialog = this.dialog.open(ElectronicIdentificationTermsComponent, { autoFocus: false, maxHeight: '90vh' })

    dialog.componentInstance.openedAsDialog = true

    dialog
      .afterClosed()
      .pipe(filter((v): v is true => !!v))
      .subscribe({
        next: (v) => this.agreement.setValue({ electronicIdentificationAgree: v }),
      })
  }

  openSmartIDDialog(): void {
    this.agreement.markAsTouched()

    if (this.agreement.invalid) {
      return
    }

    this.dialog
      .open(SmartIDDialogComponent, { width: '500px' })
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe({
        next: (identity: Identity) => {
          this.identity = identity

          try {
            const identityData = jwt_decode<Person>(identity.signed)

            this.patchClient(identity.signed)

            this.person.reset(identityData)
          } catch (err) {
            throw new Error(`Cannot decode signed identity: ${identity.signed}`, { cause: err })
          }
        },
      })
  }

  patchClient(identity: string): void {
    this.clientId
      .pipe(
        tap(() => (this.loading = true)),
        switchMap((id) => this.api.patchClient(id, this.client, identity))
      )
      .subscribe({
        next: (client) => {
          this.loading = false
          this.client = client
          this.agreement.reset()
          this.page = 'verified'
          this.snackBar.open('Identitāte verificēta!')
        },
        error: (err) => {
          this.loading = false
          this.snackBar.httpError(err)
        },
      })
  }
}
