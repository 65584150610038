<mat-toolbar color="primary" class="mat-elevation-z3">
  <mat-toolbar-row>
    <button mat-icon-button [routerLink]="['/orders']">
      <mat-icon>close</mat-icon>
    </button>
    <span style="font-size: 20px">{{ title.getTitle() }}</span>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span style="padding-left: 40px; font-size: 18px">{{ order?.createdAt | date }}</span>
  </mat-toolbar-row>
</mat-toolbar>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div class="container">
  <div class="mat-stepper-horizontal mat-stepper-label-position-bottom">
    <div class="mat-horizontal-stepper-header-container">
      <ng-container *ngIf="order?.state !== 'canceled'; else canceledOrder">
        <ng-container *ngFor="let state of states; index as i">
          <div class="mat-stepper-horizontal-line" *ngIf="i > 0"></div>
          <div class="mat-step-header mat-horizontal-stepper-header mat-primary">
            <div class="mat-step-icon" [class.mat-step-icon-selected]="isStateCompleted(state)">
              <div class="mat-step-icon-content">
                <mat-icon>{{ stateIcon(state) }}</mat-icon>
              </div>
            </div>
            <div class="mat-step-label">
              <div class="mat-step-text-label">{{ stateTitle(state) }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #canceledOrder>
        <div class="mat-step-header mat-horizontal-stepper-header mat-primary">
          <div class="mat-step-icon" [class.mat-step-icon-selected]="isStateCompleted('canceled')">
            <div class="mat-step-icon-content">
              <mat-icon>{{ stateIcon('canceled') }}</mat-icon>
            </div>
          </div>
          <div class="mat-step-label">
            <div class="mat-step-text-label">{{ stateTitle('canceled') }}</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="order?.state === 'pending'" style="text-align: justify">
    <p>
      Farmaceits šobrīd veic pasūtījuma sagatavošanu. Nepieciešamības vai neskaidrību gadījumā farmaceits sazināsies ar
      Jums.
    </p>
    <p>Rēķins tiks nosūtīts 1 darba dienas laikā.</p>
    <p>
      Pasūtījums tiks nodots piegādei 1-2 darba dienu laikā no maksājuma saņemšanas brīža. Pirms piegādes kurjers
      sazināsies ar Jums.
    </p>
  </div>
  <app-receipt *ngIf="order && order.state !== 'pending'" [orderId]="order.id"></app-receipt>
</div>

<!-- TODO: to force to get mat stepper css -->
<mat-horizontal-stepper style="display: none">
  <mat-step> </mat-step>
</mat-horizontal-stepper>
