<mat-card class="card">
  <span *ngIf="order?.state === 'canceled'" class="canceled">Pasūtījums ir atcelts!</span>
  <mat-card-header class="card__header">
    <div class="header-button">
      <button
        *ngFor="let document of documents"
        class="button"
        mat-raised-button
        color="primary"
        [class.hidden]="order?.state === 'canceled'"
        (click)="download(document)"
      >
        <mat-icon>download</mat-icon>
        {{ document.toString().includes('/documents/R') ? 'Rēķins' : 'Pavadzīme' }}
      </button>
      <ng-container *ngIf="featureToggle">
        <ng-container *ngIf="order?.state === 'quote'">
          <button mat-raised-button color="primary" class="button" (click)="openTerms()">
            <mat-icon>credit_card</mat-icon>
            Apmaksāt
          </button>
        </ng-container>
      </ng-container>
    </div>
    <div class="header-content">
      <div class="text">
        <mat-card-title class="card--title"
          >Rēķina nr.: {{ 'MSA ' + orderId?.toString()?.padStart(6, '0') }}</mat-card-title
        >
        <mat-card-title class="title2"><strong> Nosūtītājs</strong></mat-card-title>
        <span>SIA "Saules Aptieka"</span>
        <span>Juridiskā adrese: Krūzes iela 38, Rīga, LV-1002, Latvija</span>
        <span>Reģ. Nr.: 40003373494</span>
        <span>Nod. maks. nr.: LV40003373494</span>
        <span>Banka: A/S Swedbank</span>
        <span>Bankas kods: HABALV22</span>
        <span>Konta numurs: LV33HABA0001408039408</span>
      </div>
      <div class="text">
        <mat-card-title class="card--title">Datums: {{ order?.quotedAt | date: 'yyyy.MM.dd' }}</mat-card-title>
        <mat-card-title class="title2"><strong>Saņēmējs</strong></mat-card-title>
        <span>{{ order?.client?.firstName + ' ' + order?.client?.lastName }}</span>
        <ng-container *ngIf="order?.address?.addressLine1 === 'Brīvības iela 68'; else delivery">
          <span>Piegādes adrese: Saules Aptieka, {{ order?.address! | address }}</span>
        </ng-container>
        <ng-template #delivery>
          <span>Piegādes adrese: {{ order?.address! | address }}</span>
        </ng-template>
        <span *ngFor="let phone of order?.client?.phones">Tel.nr.: {{ phone.number | mask: '+000 00 000 000' }}</span>
      </div>
    </div>
  </mat-card-header>
  <mat-card-subtitle class="card__subtitle"><strong>Saņēmēja piezīmes:</strong> {{ order?.notes }}</mat-card-subtitle>
  <mat-card-content>
    <table class="table" mat-table [dataSource]="order?.receipt?.items ?? []">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Nr</th>
        <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="columns.length">
          <table class="table__footer" *ngIf="order?.receipt">
            <tbody class="right">
              <tr *ngFor="let breakdown of order?.receipt?.total?.breakdown">
                <td>PVN {{ breakdown.VATRate }}%:</td>
                <td>{{ breakdown.VAT | currency }}</td>
              </tr>
              <tr>
                <td>Summa ar PVN pirms atlaides:</td>
                <td>{{ order?.receipt?.total?.grossBeforeItemDiscount | currency }}</td>
              </tr>
              <tr *ngIf="order?.receipt?.markdowns?.length !== 0">
                <td *ngFor="let markdown of order?.receipt?.markdowns">
                  Papildus atlaide {{ markdown.discount?.percentage + '%' }}:
                </td>
                <td>-{{ order?.receipt?.total?.discount | currency }}</td>
              </tr>
              <tr *ngIf="order?.receipt?.payers?.length !== 1">
                <td style="vertical-align: text-top">Atlaides:</td>
                <div *ngFor="let payer of order?.receipt?.payers" class="table__payers">
                  <td *ngIf="payer.client">{{ payer.client.name }} -{{ payer.amount | currency }}</td>
                </div>
                <div *ngFor="let item of filterMarkdowns(order?.receipt?.items)" class="table__payers">
                  <td>{{ item }} -{{ order?.receipt?.total?.itemDiscount | currency }}</td>
                </div>
              </tr>
              <tr>
                <td><strong> Summa apmaksai:</strong></td>
                <td>
                  <strong>{{ order?.receipt?.total?.consumerGross | currency }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </ng-container>
      <ng-container matColumnDef="prescriptionNumber">
        <th mat-header-cell *matHeaderCellDef>Receptes nr.</th>
        <td mat-cell *matCellDef="let item">{{ item.prescription?.number }}</td>
        <td mat-footer-cell *matFooterCellDef class="hidden"></td>
      </ng-container>
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>Prece</th>
        <td mat-cell *matCellDef="let item">{{ item.product?.name }}</td>
        <td mat-footer-cell *matFooterCellDef class="hidden"></td>
      </ng-container>
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef>Mērvienība</th>
        <td mat-cell *matCellDef="let item">{{ item.unit?.name || 'gab' }}</td>
        <td mat-footer-cell *matFooterCellDef class="hidden"></td>
      </ng-container>
      <ng-container matColumnDef="discount">
        <th mat-header-cell *matHeaderCellDef>Atlaide</th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="item.markdowns && item.markdowns.length > 0; else itemPayment">
            <div *ngFor="let markdown of item.markdowns">
              <i
                >{{ markdown.discount.name + ' ' + markdown.discount.percentage }}%&nbsp;{{
                  '-' + markdown.amount | currency
                }}</i
              >
            </div>
          </ng-container>
          <ng-template #itemPayment>
            <div *ngFor="let payment of item.payments">
              <div *ngIf="payment.discount">
                <i>{{ payment.discount?.name + ' ' + payment.discount?.percentage }}%</i>
                {{ '-' + getPaymentAmount(item.payments, 'insurer') | currency }}
              </div>
            </div>
          </ng-template>
        </td>
        <td mat-footer-cell *matFooterCellDef class="hidden"></td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <td mat-cell *matCellDef="let item">{{ item.quantity }}</td>
        <th mat-header-cell *matHeaderCellDef>Daudzums</th>
        <td mat-footer-cell *matFooterCellDef class="hidden"></td>
      </ng-container>
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>Cena</th>
        <td mat-cell *matCellDef="let item">
          {{ item.price?.gross ?? '0.00' | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="hidden"></td>
      </ng-container>
      <ng-container matColumnDef="totalBeforeDiscount">
        <th mat-header-cell *matHeaderCellDef>Summa pirms atlaides</th>
        <td mat-cell *matCellDef="let item">
          {{ item.totalBeforeDiscount ?? '0.00' | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="hidden"></td>
      </ng-container>
      <ng-container matColumnDef="totalAfterDiscount">
        <th mat-header-cell *matHeaderCellDef>Summa pēc atlaides</th>
        <td mat-cell *matCellDef="let item">
          {{ getPaymentAmount(item.payments, 'consumer') | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="hidden"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
      <tr mat-footer-row *matFooterRowDef="columns"></tr>
    </table>
  </mat-card-content>
</mat-card>
<ng-container *ngIf="!featureToggle">
  <div *ngIf="order?.state === 'quote'" class="wrapper">
    <div class="payment">
      <div class="payment__agreement">
        <section>
          <mat-checkbox [formControl]="agreement"> </mat-checkbox>
        </section>
        <span>
          Apliecinu, ka piekrītu
          <span (click)="openTerms()" class="rules"><em>personas datu apstrādes politikai</em></span>
        </span>
      </div>
      <button
        mat-raised-button
        color="primary"
        class="button"
        (click)="pay()"
        [disabled]="agreement.value === false || hasEveryPayLink"
      >
        Apmaksāt
      </button>
    </div>
  </div>
</ng-container>
