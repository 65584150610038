<mat-toolbar color="primary" class="mat-elevation-z3">
  <mat-toolbar-row>
    <button mat-icon-button [routerLink]="['/orders']">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span style="padding-left: 40px; font-size: 20px">{{ title.getTitle() }}</span>
  </mat-toolbar-row>
</mat-toolbar>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<main class="contacts" *ngIf="client | async as client">
  <div class="phones">
    <h4 class="phones_title">Tālruņa numurs</h4>
    <mat-list class="phones_list">
      <ng-container *ngFor="let phone of client.phones; index as i">
        <mat-list-item>
          <mat-icon style="align-self: center; color: inherit; margin-top: 30px" matListItemIcon>phone</mat-icon>
          <div style="display: flex; justify-content: space-between; align-items: center">
            <span class="line" style="font-size: 18px" matListItemLine>{{
              phone.number | mask: '+000 00 000 000'
            }}</span>
            <div>
              <button matTooltip="Labot" (click)="editPhone(client, phone.number, i)" class="line" mat-icon-button>
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
        <mat-divider *ngIf="i + 1 < client.phones?.length!"></mat-divider>
      </ng-container>
    </mat-list>
  </div>
  <div>
    <h4>Adreses</h4>
    <mat-list class="list">
      <ng-container *ngFor="let address of client.addresses; index as i">
        <mat-list-item>
          <mat-icon style="align-self: center; color: inherit" matListItemIcon>home</mat-icon>
          <div style="display: flex; justify-content: space-between">
            <div>
              <span class="line" matListItemLine>{{ address.addressLine1 }}</span>
              <span class="line" matListItemLine>{{ address.city }}, {{ address.postCode }}</span>
            </div>
            <button matTooltip="Labot" (click)="editAddress(address)" class="line" mat-icon-button>
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-list-item>
        <mat-divider *ngIf="i + 1 < client.addresses?.length!"></mat-divider>
      </ng-container>
    </mat-list>
    <button type="button" mat-raised-button color="primary" (click)="addAddress()">Pievienot jaunu adresi</button>
  </div>
</main>
