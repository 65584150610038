<div style="width: 33vw; min-width: 250px">
  <mat-card *ngIf="page === 'login'; else passwordReset">
    <mat-card-content>
      <form class="form" [formGroup]="form">
        <button class="button" type="button" mat-raised-button (click)="signInWithGoogle()">
          <mat-icon svgIcon="google"></mat-icon>&nbsp;Pieslēgties ar Google
        </button>

        <div class="or"><span>VAI</span></div>

        <mat-form-field appearance="outline">
          <mat-label>E-pasta adrese</mat-label>
          <input type="email" matInput autocomplete="username" formControlName="email" />
          <mat-error *ngIf="form.controls.email.touched && form.controls.email.invalid"
            >E-pasta adrese ir nepieciešama</mat-error
          >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Parole</mat-label>
          <input type="password" autocomplete="current-password" matInput formControlName="password" />
          <mat-error *ngIf="form.controls.password.touched && form.controls.password.invalid"
            >Parole ir nepieciešama</mat-error
          >
        </mat-form-field>
        <button class="button" mat-raised-button color="primary" (click)="signInWithEmailAndPassword()">
          Pieslēgties
        </button>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="space-between" style="padding: 16px">
      <button class="button" mat-dialog-close mat-button [routerLink]="['/sign-up']">Reģistrēties</button>
      <button class="button" mat-button (click)="showPasswordReset()">Aizmirsi paroli?</button>
    </mat-card-actions>
  </mat-card>
</div>

<ng-template #passwordReset>
  <mat-card fxLayout="column">
    <mat-card-content>
      <form class="form" (ngSubmit)="sendPasswordResetEmail()">
        <mat-form-field appearance="outline">
          <mat-label>E-pasta adrese</mat-label>
          <input matInput autocomplete="username" type="email" [formControl]="resetPasswordEmail" />
          <mat-error>E-pasta adrese ir nepieciešama</mat-error>
        </mat-form-field>
        <button class="button" mat-raised-button color="primary" [disabled]="resetPasswordEmail.invalid">
          Nosūtīt paroles nomaiņas saiti
        </button>
        <button type="button" class="button" mat-button (click)="page = 'login'">Atpakaļ pie pieslēgšanās</button>
      </form>
    </mat-card-content>
  </mat-card>
</ng-template>
