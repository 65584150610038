<div class="container">
  <h3>{{ edit ? 'Labot tālruņa numuru' : 'Pievienot jaunu numuru' }}</h3>
  <button type="button" class="grow" mat-icon-button mat-dialog-close>
    <mat-icon fontIcon="close"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <form class="form" [formGroup]="form">
    <mat-form-field class="form__field" appearance="outline">
      <mat-label class="label">Tālruņa numurs</mat-label>
      <input required formControlName="number" matInput prefix="+371 " mask="00 000 000" />
      <mat-error *ngIf="form.controls.number.touched && form.controls.number.invalid"
        >Tālruņa numurs ir nepieciešams</mat-error
      >
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog__actions">
  <button mat-button mat-dialog-close>Atcelt</button>
  <button color="primary" mat-flat-button (click)="save()" cdkFocusInitial>Saglabāt</button>
</mat-dialog-actions>
