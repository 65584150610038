import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserModule, DomSanitizer, Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'

import { initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { Auth, getAuth, provideAuth } from '@angular/fire/auth'
import { AuthGuardModule } from '@angular/fire/auth-guard'

import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import localeLv from '@angular/common/locales/lv'
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AddressComponent } from './components/address/address.component'
import { ConfirmNewOrderDialogComponent } from './components/confirm-new-order-dialog/confirm-new-order-dialog.component'
import { ContactsComponent } from './components/contacts/contacts.component'
import { DeliveryConditionsComponent } from './components/delivery-conditions/delivery-conditions.component'
import { ElectronicIdentificationTermsComponent } from './components/electronic-identification-terms/electronic-identification-terms.component'
import { FooterComponent } from './components/footer/footer.component'
import { HomeComponent } from './components/home/home.component'
import { NewPhoneComponent } from './components/new-phone/new-phone.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { OrderComponent } from './components/order/order.component'
import { OrdersNewComponent } from './components/orders-new/orders-new.component'
import { OrdersComponent } from './components/orders/orders.component'
import { PersonIdentityComponent } from './components/person-identity/person-identity.component'
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component'
import { ReceiptComponent } from './components/receipt/receipt.component'
import { SignInComponent } from './components/sign-in/sign-in.component'
import { SignUpDoneComponent } from './components/sign-up-done/sign-up-done.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'
import { SmartIdBasicAccountComponent } from './components/smart-id-basic-account/smart-id-basic-account.component'
import { SmartIDDialogComponent } from './components/smart-id-dialog/smart-id-dialog.component'
import { UnverifiedUserComponent } from './components/unverified-user/unverified-user.component'
import { UseOfPersonalDataComponent } from './components/use-of-personal-data/use-of-personal-data.component'
import { AuthInterceptor } from './interceptors/auth.interceptor'
import { AddressPipe } from './pipes/address.pipe'
import { BASE_PATH } from './services/api'

registerLocaleData(localeLv, 'lv')

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    OrdersComponent,
    NotFoundComponent,
    OrdersNewComponent,
    OrderComponent,
    AddressPipe,
    SignUpDoneComponent,
    UnverifiedUserComponent,
    ReceiptComponent,
    SmartIDDialogComponent,
    HomeComponent,
    AddressComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    ElectronicIdentificationTermsComponent,
    UseOfPersonalDataComponent,
    DeliveryConditionsComponent,
    ConfirmNewOrderDialogComponent,
    PersonIdentityComponent,
    SmartIdBasicAccountComponent,
    ContactsComponent,
    NewPhoneComponent,
  ],
  imports: [
    ApmModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthGuardModule,
    provideAuth(() => getAuth()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    {
      provide: BASE_PATH,
      useValue: environment.apiUrl,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
    {
      provide: LOCALE_ID,
      useValue: 'lv',
    },
    ApmService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Title,
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(service: ApmService, auth: Auth, matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    const apm = service.init(environment.apm)

    auth.onAuthStateChanged((user) => {
      const username = user?.email || undefined
      apm.setUserContext({ username })
    })

    matIconRegistry.addSvgIcon(
      'google',
      domSanitizer.bypassSecurityTrustResourceUrl('https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg')
    )
  }
}
