<mat-toolbar color="primary" class="mat-elevation-z3">
  <mat-toolbar-row>
    <button mat-icon-button (click)="router.navigate([''])">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span style="padding-left: 40px; font-size: 20px">{{ title.getTitle() }}</span>
  </mat-toolbar-row>
</mat-toolbar>

<div class="container content">
  <span
    >Lai varētu turpināt pasūtījuma veikšanu, lūdzu, apstipriniet savu e-pasta adresi, noklikšķinot uz saites, ko
    saņemsiet savā e-pastkastē. Ja neredzat saņemto e-pastu, lūdzu, pārbaudiet arī savu <em>spam</em> mapi.</span
  >

  <button class="button" mat-raised-button color="primary" (click)="sendEmailVerification()">Nosūtīt atkārtoti</button>
</div>
