import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Auth, getIdTokenResult } from '@angular/fire/auth'
import { StorageReference, getDownloadURL, getStorage, listAll, ref } from '@angular/fire/storage'
import { FormBuilder, FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, filter, from, map } from 'rxjs'
import { featureToggle } from 'src/app/featureToggle'
import { DefaultService, ItemPayment, Order, OrderState, PaymentState, ReceiptItem } from 'src/app/services/api'
import { SnackBarService } from 'src/app/services/snackbar.service'
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component'

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit, OnDestroy {
  @Input() orderId!: number | undefined

  readonly featureToggle = featureToggle.betterPayment

  agreement!: FormControl<boolean>

  readonly columns = [
    'position',
    'prescriptionNumber',
    'product',
    'quantity',
    'unit',
    'price',
    'totalBeforeDiscount',
    'discount',
    'totalAfterDiscount',
  ]

  readonly clientId = from(getIdTokenResult(this.auth.currentUser!)).pipe(map((token) => token.claims.clientId))

  order: Order | undefined

  documents: StorageReference[] = []

  hasEveryPayLink = false

  readonly subscription = new Subscription()

  constructor(
    public auth: Auth,
    private api: DefaultService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: SnackBarService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (!this.featureToggle) {
      this.agreement = this.fb.nonNullable.control(false)
    }

    this.api
      .getOrder(this.orderId!, [
        'address',
        'client',
        'receipt.items.markdowns.discount.offeredBy',
        'receipt.markdowns.discount.offeredBy',
        'receipt.payers.client',
      ])
      .subscribe((order) => (this.order = order))

    this.subscription.add(
      this.clientId.subscribe({
        next: (id) => {
          const storage = getStorage()
          const clientRef = ref(storage, `clients/${id}/documents`)

          listAll(clientRef)
            .then((list) => {
              const matchOrderRef = list.items.filter((itemRef) => {
                return itemRef.toString().endsWith(`${this.orderId?.toString().padStart(6, '0')}.pdf`)
              })

              // "P" is before "R" in document filename
              this.documents = matchOrderRef.sort((a, b) => b.name.localeCompare(a.name))
            })
            .catch((err) => this.snackBar.httpError(err))
        },
      })
    )

    this.subscription.add(
      this.route.queryParamMap
        .pipe(filter((params) => params.has('payment_reference') && params.has('order_reference')))
        .subscribe({
          next: () => {
            this.router.navigate(['./'], { relativeTo: this.route, replaceUrl: true })

            this.api.getPaymentState(this.orderId!).subscribe({
              next: (v) => {
                if (this.order && v.state === PaymentState.Settled) {
                  this.order.state = OrderState.Paid

                  this.snackBar.open('Maksājums ir veiksmīgs')
                } else if (v.state === PaymentState.SentForProcessing) {
                  this.snackBar.open('Maksājums tiek apstrādāts')
                } else {
                  this.snackBar.open('Kaut kas nogāja greizi, mēs esam informēti par kļūdu.')
                }
              },
              error: (err) => this.snackBar.httpError(err),
            })
          },
        })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  download(document: StorageReference): void {
    getDownloadURL(document)
      .then((url) => window.open(url, '_blank'))
      .catch((err) => this.snackBar.httpError(err))
  }

  filterMarkdowns(items: ReceiptItem[] | undefined) {
    const clientName: string[] = []
    items
      ?.filter((item) => item.markdowns?.length !== 0)
      .map(
        (v) =>
          v.markdowns?.map((markdown) => {
            const offeredByName = markdown.discount?.offeredBy?.name
            if (offeredByName) {
              clientName.push(offeredByName)
            }
          })
      )

    return [...new Set(clientName)]
  }

  pay(): void {
    const redirectUrl = `${document.location.origin}/orders/${this.orderId}`

    this.hasEveryPayLink = true

    this.api.getPaymentLink(this.orderId!, redirectUrl).subscribe({
      next: (paymentLink) => (window.location.href = paymentLink.uri),
      error: (err) => {
        this.hasEveryPayLink = false
        this.snackBar.httpError(err)
      },
    })
  }

  openTerms(): void {
    const dialog = this.dialog.open(
      PrivacyPolicyComponent,
      this.featureToggle
        ? { autoFocus: false, width: '600px', height: '500px' }
        : {
            autoFocus: false,
            maxHeight: '90vh',
          }
    )
    dialog.componentInstance.openedAsDialog = true

    dialog
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe((v) => (this.featureToggle ? this.pay() : this.agreement.setValue(v)))
  }

  getPaymentAmount(payment: ItemPayment[] | undefined, payerType: 'insurer' | 'consumer'): string | undefined {
    return payment?.find((v) => (payerType === 'consumer' ? v.discount === undefined : !!v.discount))?.amount
  }
}
