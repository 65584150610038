import { Pipe, PipeTransform } from '@angular/core'
import { Address } from '../services/api'

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(value: Address): string {
    return [value?.addressLine1, value?.addressLine2, value?.city, value?.postCode, value?.countryCode]
      .filter((v) => !!v)
      .join(', ')
  }
}
