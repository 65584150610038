import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export const equalValidator =
  (to: string): ValidatorFn =>
  (v: AbstractControl): ValidationErrors | null =>
    v!.value === v.root?.get(to)?.value ? null : { equal: true }

export const clientCardValidator = (ctrl: AbstractControl): ValidationErrors | null => {
  if (ctrl.value.length === 0) {
    return null
  }

  const value = `2900000${ctrl.value}`

  if (/$\d{13}^/.test(value)) {
    return { clientCard: 'format' }
  }

  const checksum = value
    .substring(0, 12)
    .split('')
    .map((v, i) => (i % 2 === 0 ? +v : +v * 3))
    .reduce((r, v) => r + v, 0)

  return +value[12] === (10 - (checksum % 10)) % 10 ? null : { clientCard: 'checksum' }
}
