/* eslint-disable @typescript-eslint/no-explicit-any */

// deepTrim recursively trims all string values in given object.
export const deepTrim = <T>(values: T): T => {
  if (values) {
    for (const property in values) {
      if ((values as any).hasOwnProperty(property)) {
        if (typeof values[property] === 'object' || values[property] instanceof Object) {
          deepTrim(values[property])
        } else if (typeof values[property] === 'string' || values[property] instanceof String) {
          values[property] = (values[property] as any).trim()
        }
      }
    }
  }

  return values
}
