<mat-toolbar color="primary" class="mat-elevation-z3">
  <button [matMenuTriggerFor]="menu" mat-icon-button>
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div class="menu" *ngIf="client | async as client">
      <img *ngIf="photoUrl; else noPhoto" class="photo" [src]="photoUrl" alt="photo" />
      <span class="menu__text">{{ client.firstName }} {{ client.lastName }}</span>
      <mat-divider></mat-divider>
      <button mat-menu-item [routerLink]="['/profile/contacts']">
        <mat-icon>import_contacts</mat-icon>Tālruņa nr. un adreses
      </button>
      <button mat-menu-item [routerLink]="['/profile/identity']">
        <mat-icon>person_check</mat-icon>Personas identitāte
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item style="font-size: 16px" (click)="signOut()"><mat-icon>logout</mat-icon>Iziet</button>

      <ng-template #noPhoto>
        <button class="btn__icon" mat-fab color="primary" (click)="(false)"><mat-icon>person</mat-icon></button>
      </ng-template>
    </div>
  </mat-menu>

  <span style="padding-left: 40px; font-size: 20px">{{ title.getTitle() }}</span>
</mat-toolbar>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div class="container" fxLayout="column">
  <button class="button" mat-raised-button [routerLink]="['/orders-new']" color="primary">
    <mat-icon>add</mat-icon> Izveidot jaunu pasūtījumu
  </button>
  <ng-container *ngIf="orders | async as orders">
    <mat-selection-list *ngIf="orders.length > 0; else emptyOrders" [multiple]="false">
      <ng-container *ngFor="let order of orders; index as i">
        <mat-divider *ngIf="i > 0"></mat-divider>
        <mat-list-option class="ordersList" (click)="router.navigate(['orders', order.id])">
          <mat-icon matListItemIcon style="align-self: center; color: inherit" [matTooltip]="tooltip(order.state!)">{{
            icon(order.state!)
          }}</mat-icon>
          <span matListItemTitle>Nr. {{ order.id }} ({{ order.createdAt | date }})</span>
          <span matListItemLine style="color: inherit">{{ order.notes }}</span>
          <ng-container *ngIf="order.state !== 'canceled' && order.state !== 'pending'">
            <ng-container *ngFor="let payer of order.receipt?.payers">
              <span style="color: black" *ngIf="!payer.client || payer.client === order.client?.id">{{
                payer.amount | currency
              }}</span>
            </ng-container>
          </ng-container>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
    <ng-template #emptyOrders>
      Vēl neesat pasūtījis nevienas recepšu zāles. Lūdzu, veiciet pasūtījumu, lai izmantotu mūsu ērto un ātro recepšu
      zāļu piegādes pakalpojumu.
    </ng-template>
  </ng-container>
</div>
