<div *ngIf="!verificationCode; else code">
  <mat-dialog-content class="dialog__personIdentity">
    <mat-form-field appearance="outline">
      <mat-label>Personas kods</mat-label>
      <input
        matInput
        autocomplete="off"
        [formControl]="personIdentity"
        mask="000000-00000"
        [dropSpecialCharacters]="false"
        required
      />
      <mat-hint>Ievadiet savu personas kodu</mat-hint>
      <mat-error>Personas kods ir nepieciešams</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="actions" align="end">
    <button mat-button mat-dialog-close>Atcelt</button>
    <button mat-flat-button color="primary" (click)="confirm()">Pieslēgties</button>
  </mat-dialog-actions>
</div>
<ng-template #code>
  <mat-dialog-content class="dialog__Smart-ID">
    <img class="dialog__img" src="/assets/images/sign-up/smart-id-logo2.svg" alt="Smart-ID logo" />
    <div>
      Jūsu kontroles kods:<strong style="font-size: 20px; padding-left: 5px">{{ verificationCode?.code }}</strong>
    </div>
    <div>
      Jūsu kontroles koda derīguma termiņš beigsies pēc: <strong>{{ counter }}s</strong>
    </div>
    <div style="margin-top: 20px">
      <mat-icon style="vertical-align: bottom">info</mat-icon>
      Pieprasījums ir nosūtīts uz Jūsu viedierīci. Salīdziniet kodu un apstipriniet, ievadot PIN1.
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions" align="end">
    <button mat-raised-button mat-dialog-close>Atcelt</button>
  </mat-dialog-actions>
</ng-template>
