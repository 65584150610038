<footer class="footer">
  <div class="footer-container">
    <div class="row-contacts">
      <div class="flex contacts-content">
        <span class="contacts-item">
          <span class="icon icon-one"></span> <a class="link" href="tel:+37167812697">+371 67 812 697</a>
          <a class="link" href="tel:+37120203888">+371 20 203 888</a></span
        >
        <span class="contacts-item">
          <span class="icon icon-two"></span>
          <a class="link" href="mailto:piegade@saulesaptieka.lv">piegade@saulesaptieka.lv</a></span
        >
      </div>
      <span>Darba dienās 8:00-16:00</span>
    </div>
    <div class="footer-content">
      <div class="flex column gap-small">
        <div class="footer-heading">Noteikumi</div>
        <nav class="flex column gap-small line nav-container">
          <a class="link" [routerLink]="['delivery-terms']">Piegādes nosacījumi</a>
          <a class="link" [routerLink]="['privacy-policy']">Personas datu apstrādes politika</a>
          <a class="link" [routerLink]="['agreement']">Piekrišana personas datu izmantošanai</a>
          <a class="link" [routerLink]="['identification-terms']">
            Elektroniskās identifikācijas risinājuma lietošanas noteikumi
          </a>
        </nav>
      </div>
      <div class="flex column gap-small">
        <div class="footer-heading">SIA "SAULES APTIEKA"</div>
        <div class="flex column line">
          <span><strong>Adrese:</strong> Brīvības iela 68, Rīga, LV-1011, Latvija</span>
          <span><strong>Tel. Nr.:</strong> <a class="link" href="tel:+37167506927"> +371 67 506 927</a> </span>
          <span
            ><strong>E-pasts:</strong>&nbsp;<a class="link" href="mailto:saule10@saulesaptieka.lv"
              >saule10@saulesaptieka.lv</a
            >
          </span>
          <div class="footer__working-hours">
            <span><strong>Darba laiks:</strong></span>
            <span>
              Pr.-Pk. 8:00-22:00 <br />
              S.-Sv. 10:00-20:00
            </span>
          </div>
        </div>
      </div>
      <div class="flex column gap-small col-details">
        <div class="footer-heading">SIA "SAULES APTIEKA"</div>
        <div class="flex column line">
          <span><strong>Juridiskā adrese:</strong> Krūzes iela 38, Rīga, LV-1002, Latvija</span>
          <span><strong>Reģ. Nr.:</strong> 40003373494</span>
          <span><strong>Banka:</strong> A/S Swedbank</span>
          <span><strong>Bankas kods:</strong> HABALV22</span>
          <span><strong>Konta Nr.:</strong> LV33HABA0001408039408</span>
        </div>
      </div>
    </div>
    <div class="row-logo">
      <div class="logo-content">
        <div class="logo-wrapper">
          <img class="logo" src="/assets/images/footer/mc.svg" alt="mastercard logo" />
        </div>
        <div class="logo-wrapper">
          <img class="logo logo-big" src="/assets/images/footer/visa.svg" alt="visa logo" />
        </div>
        <div class="logo-wrapper">
          <img class="logo logo-big" src="/assets/images/footer/mc-idcheck.svg" alt="mastercard id check logo" />
        </div>
        <div class="logo-wrapper">
          <img class="logo logo-big" src="/assets/images/footer/visa-secure.svg" alt="visa secure logo" />
        </div>
        <div class="logo-wrapper">
          <img class="logo" src="/assets/images/footer/swedbank.svg" alt="swedbank logo" />
        </div>
        <div class="logo-wrapper">
          <img class="logo" src="/assets/images/footer/citadele.svg" alt="citaele logo" />
        </div>
        <div class="logo-wrapper">
          <img class="logo" src="/assets/images/footer/seb.svg" alt="seb logo" />
        </div>
        <div class="logo-wrapper">
          <img class="logo" src="/assets/images/footer/luminor.svg" alt="luminor logo" />
        </div>
      </div>
    </div>
  </div>
  <div class="created-by">
    Veidots ar <span class="icon-love" [innerHTML]="heart"></span>
    <a class="link" href="https://expect.digital" target="_blank">expect/digital</a>
  </div>
</footer>
