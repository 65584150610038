import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { debounceTime, filter, fromEvent, Subscription } from 'rxjs'
import { SignInComponent } from '../sign-in/sign-in.component'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  showsIcon = true

  readonly subscription = new Subscription()

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    if (sessionStorage.getItem('afterLogin')) {
      this.openSignIn()
    }

    this.subscription.add(
      fromEvent(window, 'scroll')
        .pipe(
          debounceTime(100),
          filter(() => window.scrollY >= window.innerHeight / 4)
        )
        .subscribe(() => {
          this.showsIcon = false
          this.subscription.unsubscribe()
        })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  openSignIn() {
    this.dialog
      .open(SignInComponent)
      .afterClosed()
      .subscribe(() => sessionStorage.removeItem('afterLogin'))
  }
}
