import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Auth } from '@angular/fire/auth'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { getIdTokenResult } from 'firebase/auth'
import { Observable, Subscription, from } from 'rxjs'
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators'
import { DefaultService, IdentityVerificationState, Order } from 'src/app/services/api'
import { SnackBarService } from 'src/app/services/snackbar.service'
import { ConfirmNewOrderDialogComponent } from '../confirm-new-order-dialog/confirm-new-order-dialog.component'
import { saulesAptiekaClientId } from '../order/order.component'

type DeliveryType = 'userAddress' | 'parcelMachine'

@Component({
  selector: 'app-orders-new',
  templateUrl: './orders-new.component.html',
  styleUrls: ['./orders-new.component.scss'],
  animations: [
    trigger('showDeliveryType', [
      state('show', style({ height: '*' })),
      state(
        'hide',
        style({
          height: '0px',
        })
      ),
      transition('show => hide', [animate('0.25s ease-out')]),
      transition('hide => show', [animate('0.25s ease-in')]),
    ]),
  ],
})
export class OrdersNewComponent implements OnInit, OnDestroy {
  loading = false

  readonly form = this.fb.nonNullable.group({
    notes: ['', Validators.required],
    delivery: ['', Validators.required],
    addressId: [0, Validators.required],
  })

  readonly addresses = from(getIdTokenResult(this.auth.currentUser!)).pipe(
    map((token) => +(token.claims.clientId as string)),
    switchMap((clientId) => this.api.getClientAddresses(clientId)),
    shareReplay(1)
  )

  readonly parcelMachineAddressId = this.api.getClientAddresses(saulesAptiekaClientId).pipe(
    map((addresses) => addresses.find((x) => x.addressLine1 === 'Brīvības iela 68')),
    map((address) => address?.id || 0)
  )

  readonly subscription = new Subscription()

  readonly verificationPending$ = from(getIdTokenResult(this.auth.currentUser!)).pipe(
    map((token) => +(token.claims.clientId as string)),
    switchMap((id) => this.api.getClient(id)),
    map((c) => c.identityVerification !== IdentityVerificationState.Verified),
    shareReplay(1)
  )

  constructor(
    public title: Title,
    public auth: Auth,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private api: DefaultService,
    private snackBar: SnackBarService,
    private router: Router
  ) {}

  get fromForm(): Order {
    const { notes, addressId } = this.form.getRawValue()

    return {
      notes,
      address: {
        id: addressId,
      },
    }
  }

  ngOnInit() {
    this.title.setTitle('Izveidot jaunu pasūtījumu')

    this.subscription.add(
      (this.form.get('delivery')?.valueChanges as Observable<DeliveryType>)
        .pipe(
          switchMap((v) =>
            v === 'parcelMachine'
              ? this.parcelMachineAddressId
              : this.addresses.pipe(map((addresses) => addresses[0]?.id || 0))
          )
        )
        .subscribe((v) => this.form.controls.addressId.setValue(v))
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  openConfirmOrder(): void {
    if (this.form.invalid) {
      return
    }

    this.dialog
      .open(ConfirmNewOrderDialogComponent)
      .afterClosed()
      .pipe(
        filter((v) => !!v),
        tap(() => (this.loading = true)),
        switchMap(() => this.api.createOrder(this.fromForm))
      )
      .subscribe({
        next: () => {
          this.loading = false
          this.snackBar.open('Pasūtījums ir veikts, farmaceits sagatavos un izsūtīs piedāvājumu.')
          this.router.navigate(['', 'orders'])
        },
        error: (err) => {
          this.loading = false
          this.snackBar.httpError(err)
        },
      })
  }
}
