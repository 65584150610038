<mat-toolbar color="primary" class="mat-elevation-z3" *ngIf="!openedAsDialog">
  <button mat-icon-button (click)="goBack()">
    <mat-icon>close</mat-icon>
  </button>
  <span class="title">{{ title.getTitle() }}</span>
</mat-toolbar>
<div class="container text" [class.dialog]="openedAsDialog">
  <h3>Piekrišana personas datu izmantošanai.</h3>

  <p>
    1) Piekrītu, norādītās informācijas (vārds, uzvārds, personas kods, tālruņa nr., e-pasts, adrese) saglabāšanai SIA
    “Saules aptieka” informācijas sistēmā (klientu reģistrā) un izmantošanai, lai aptieka varētu apstrādāt un izsniegt
    zāļu receptes, sniegt pilnvērtīgu farmaceitisko aprūpi attālināti un veikt zāļu piegādi, ja nepieciešams, datus
    iekļaujot SIA “Saules Aptieka”; <sup>1, 2, 3, 4, 5, 6</sup>
  </p>
  <p>
    2) Piekrītu, ka mans personas kods tiek izmantots, lai farmaceits varētu apskatīt manas receptes E-veselībā;
    <sup>4</sup>
  </p>
  <p>
    3) Piekrītu, uz šajā Anketā norādīto e-pastu vai tālruņa numuru saņemt man paredzēto farmaceitisko informāciju
    (t.sk. konsultācijas par zāļu lietošanu);<sup> 5</sup>
  </p>
  <p>
    4) Piekrītu par elektroniski pieteikto recepšu un citu pakalpojumu savlaicīgu apmaksu ar tiešsaistes maksājumu vai
    bankas pārskaitījumu un saņemšanu norādītajā laikā; <sup>7</sup>
  </p>
  <p>
    5) Apstiprinu, ka esmu iepazinies ar zāļu piegādes nosacījumiem (skatīt SIA “Saules aptieka” Zāļu piegādes
    nosacījumi); <sup>6</sup>
  </p>
  <p>
    6) Apstiprinu, ka mani personas dati ir pareizi un lūdzu SIA „Saules aptieka”, Saules aptieka – 10, Brīvības ielā
    68, Rīgā, sniegt man farmaceitisko aprūpi attālināti, tai skaitā nodrošināt man parakstīto zāļu nokomplektēšanu un
    piegādi adresē par to vienojoties iepriekš.
  </p>
  <h5>Paskaidrojumi:</h5>
  <small>
    <p>
      <sup>1</sup>

      Datu apstrādes pārzinis ir SIA „Saules aptieka” (Juridiskā adrese: Krūzes iela 38, Rīga, LV-1002.). Informējam, ka
      SIA „Saule aptieka” personas datus apstrādā un uzglabā, ievērojot LR MK 2010.gada 23.marta noteikumus Nr.288
      „Aptieku darbības noteikumi”, kā arī citus LR normatīvos aktus kas nosaka prasības personas datu apstrādei un
      aizsardzībai. SIA „Saules aptieka” ievēro konfidencialitāti un neizpauž trešajām personām jebkuru informāciju, kas
      iegūta pasūtījumu (elektroniska recepšu pieteikšanai un/vai zāļu piegādes) izpildes gaitā, izņemot gadījumus, kad
      šādas informācijas izpaušana nepieciešama pasūtījuma izpildei, vai to ir pieprasījusi valsts iestāde LR
      normatīvajos aktos paredzētajā kārtībā.
    </p>
    <p>
      <sup>2</sup> Dati tiek izmantoti tikai Saules Aptiekas vajadzībām, lai sniegtu kvalitatīvu farmaceitisko aprūpi
      saskaņā ar Farmācijas likuma pirmā panta ceturto punktu.
    </p>
    <p>
      <sup>3</sup> Klienta vārds, uzvārds tiek izmantots, lai identificētu fizisko personu klientu administrēšanas
      sistēmā un E- veselībā.
    </p>
    <p>
      <sup>4</sup> Klienta personas kods tiek izmantots tikai, lai nodrošinātu farmaceita piekļūšanu klienta receptēm E-
      veselībā un identificētu personu.
    </p>
    <p>
      <sup>5</sup> E-pasta adrese un/vai tālruņa numurs tiek izmantots, lai sazinātos un sniegtu klientam attālinātu
      kvalitatīvu farmaceitisko aprūpi - konsultāciju par zāļu lietošanu, informāciju par zālēm utml.
    </p>
    <p>
      <sup>6</sup> Dzīvesvietas adrese tiks izmantota, ja klients vēlēsies saņemt zāles ar piegādi uz mājām, par to
      iepriekš vienojoties ar SIA “Saules Aptieka”.
    </p>
    <p>
      <sup>7</sup> Klients samaksu par zālēm un zāļu piegādi (ar dokumentu atgriešanu) veic ar tiešsaistes maksājumu vai
      bankas pārskaitījumu pēc rēķina saņemšanas no SIA “Saules Aptieka”. Ja pasūtītās zāles ir iekļautas kompensējamo
      zāļu sarakstā, samaksu par tām SIA “Saules Aptieka” saņem saskaņā ar LR MK noteikumiem Nr.899 „Ambulatorajai
      ārstēšanai paredzēto zāļu un medicīnisko ierīču iegādes izdevumu kompensācijas kārtība”. Ja norādītie noteikumi
      paredz pacienta līdzmaksājumu, tie tiek iekļauti klienta rēķinā.
    </p></small
  >

  <mat-dialog-actions align="end" *ngIf="openedAsDialog">
    <button mat-button mat-dialog-close>Atcelt</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="true">Piekrītu</button>
  </mat-dialog-actions>
</div>
