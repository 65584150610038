import { Component, OnInit } from '@angular/core'
import { Auth } from '@angular/fire/auth'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { sendEmailVerification } from 'firebase/auth'
import { SnackBarService } from 'src/app/services/snackbar.service'

@Component({
  selector: 'app-unverified-user',
  templateUrl: './unverified-user.component.html',
  styleUrls: ['./unverified-user.component.scss'],
})
export class UnverifiedUserComponent implements OnInit {
  constructor(
    public title: Title,
    private auth: Auth,
    private snackBarService: SnackBarService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Paldies par reģistrāciju!')
  }

  sendEmailVerification(): void {
    sendEmailVerification(this.auth.currentUser!).then(
      () => {
        this.snackBarService.open('Saite, lai apstiprinātu e-pastu, ir izsūtīta.')
      },
      (err) => this.snackBarService.error(err.message)
    )
  }
}
