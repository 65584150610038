import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Auth } from '@angular/fire/auth'
import { Observable, from, switchMap } from 'rxjs'
import { environment } from 'src/environments/environment'

// TokenInterceptor ensures that token is always fresh and up to date from Firebase Auth.
// Unfortunately, openapi-generator-cli generated API client does not support async getter
// of the token. Use this instead of the blocking code:
//
//    api.configuration.credentials.jwtBearerAuth = "..."
//    api.configuration.credentials.jwtBearerAuth = () => "..."
//
// Firebase ID tokens are short lived and last for an hour
// https://firebase.google.com/docs/auth/admin/manage-sessions
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: Auth) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return request.url.startsWith(environment.apiUrl) && this.auth.currentUser
      ? from(this.auth.currentUser.getIdToken()).pipe(
          switchMap((token) => {
            request = request.clone({
              headers: request.headers.set('Authorization', `Bearer ${token}`),
            })
            return next.handle(request)
          })
        )
      : next.handle(request)
  }
}
