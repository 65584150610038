<mat-toolbar color="primary" class="mat-elevation-z3">
  <mat-toolbar-row>
    <button mat-icon-button [routerLink]="['/orders']">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span style="padding-left: 40px; font-size: 20px">{{ title.getTitle() }}</span>
  </mat-toolbar-row>
</mat-toolbar>

<div class="container content">
  <p>Lūdzu, noklikšķiniet uz “Izveidot jaunu pasūtījumu”, lai sāktu izveidot savu pirmo pasūtījumu.</p>
  <button class="button" mat-raised-button [routerLink]="['/orders-new']" color="primary">
    <mat-icon>add</mat-icon> Izveidot jaunu pasūtījumu
  </button>
</div>
