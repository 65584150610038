import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

const systemErr = '🤷 Kaut kas nogāja greizi, mēs esam informēti par kļūdu.'

export interface SnackbarValue {
  message: string
  action?: string
  config?: MatSnackBarConfig
}

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  private readonly stream = new BehaviorSubject<SnackbarValue |undefined>(undefined)

  constructor(private snackBar: MatSnackBar) {
    (this.stream.pipe(filter(v => !!v)) as Observable<SnackbarValue>).subscribe((v) => {
      this.snackBar.open(v.message, undefined, v.config)
    })
  }

  error(msg: string) {
    this.open(
      msg,
      undefined,
      {
        duration: 5000,
        panelClass: ['mat-toolbar', 'mat-warn'],
      })
  }

  httpError(e: HttpErrorResponse) {
    this.error(e.status >= 400 ? systemErr : `Kļūda: ${e.statusText}`)
  }

  open(message: string, action?: string, config?: MatSnackBarConfig) {
    config = { duration: 3000, ...config }
    this.stream.next({ message, action, config })
  }
}
