<mat-toolbar color="primary" class="mat-elevation-z3">
  <mat-toolbar-row>
    <button mat-icon-button [routerLink]="['/orders']">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span style="padding-left: 40px; font-size: 20px">{{ title.getTitle() }}</span>
  </mat-toolbar-row>
</mat-toolbar>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div class="container">
  <mat-card *ngIf="verificationPending$ | async" class="unverified">
    <mat-card-header>
      <mat-icon color="primary" fontIcon="warning"></mat-icon>
      <span class="card-title">Neapstiprināta identitāte</span>
    </mat-card-header>
    <mat-card-content>
      <p>
        Ņemiet vērā, ka farmaceits elektroniskās receptes var apstrādāt tikai klientiem ar
        <strong>verificētu identitāti</strong> (bezrecepšu medikamentus un citas preces iespējams pasūtīt arī bez
        identitātes verificēšanas)
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button class="button" mat-button color="primary" [routerLink]="['/profile/identity']">
        VERIFICĒT SAVU IDENTITĀTI
      </button>
    </mat-card-actions>
  </mat-card>
  <form class="form" [formGroup]="form">
    <span>Lai izveidotu jaunu pasūtījumu, lūdzu, norādiet papildus informāciju, tādu kā:</span>
    <ul class="text">
      <li class="list-item">
        E-receptes numuru vai medikamenta nosaukumu, ja vēlaties pasūtīt tikai vienu no izrakstītajām e-receptēm.
      </li>
      <li>
        Cita informācija, kas var palīdzēt farmaceitam sagatavot pasūtījumu (piem. pievienot pasūtījumam Ibumetin 400mg
        N100 vai D vitamīnu, vai norādiet vēlamo medikamentu iepakojumu skaitu)
      </li>
    </ul>
    <p>Farmaceits sagatavos piedāvājumu un sazināsies ar jums vienas darba dienas laikā pēc pasūtījuma saņemšanas.</p>

    <mat-form-field appearance="outline">
      <mat-label>Papildus informācija</mat-label>
      <textarea rows="3" matInput formControlName="notes"></textarea>
      <mat-hint>Norādiet jebkuru papildus informāciju farmaceitam.</mat-hint>
    </mat-form-field>
    <h4>Piegādes informācija</h4>
    <mat-radio-group class="form-radio" formControlName="delivery">
      <div>
        <mat-radio-button value="userAddress" class="delivery-type">Piegāde uz adresi</mat-radio-button>
        <div
          fxLayout="column"
          class="form-delivery"
          [@showDeliveryType]="this.form.value.delivery === 'userAddress' ? 'show' : 'hide'"
        >
          <mat-form-field appearance="outline">
            <mat-label>Adrese</mat-label>
            <mat-select formControlName="addressId">
              <mat-option *ngFor="let address of addresses | async" [value]="address.id">{{
                address | address
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.addressId.invalid">Norādiet piegādes adresi</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-radio-button value="parcelMachine" class="delivery-type">Izņemt aptiekā</mat-radio-button>
        <div
          fxLayout="column"
          class="form-delivery"
          [@showDeliveryType]="this.form.value.delivery === 'parcelMachine' ? 'show' : 'hide'"
        >
          <div>
            <p><strong>Adrese:</strong> Brīvības iela 68, Rīga</p>
            <span class="text">
              <strong>Darba laiks:</strong> <br />
              Darba dienās: 8:00-22:00<br />
              Brīvdienās: 10:00-20:00
            </span>
          </div>
        </div>
      </div>
    </mat-radio-group>
    <div>
      <p class="text">
        SIA “Saules aptieka” vietnē <em>mana.saulesaptieka.lv</em> nodrošina iespēju iegādāties recepšu un bezrecepšu
        zāles, uztura bagātinātājus, un medicīnas preces, kuriem ir reglamentēti glabāšanas nosacījumi. Līdz ar to
        iegādāto preču atgriešana nav iespējama.
      </p>
      <p class="text">
        Pasūtījumu iespējams atcelt, pirms pasūtījuma piegādes, par to iepriekš vienojoties ar SIA “Saules aptieka”
        pārstāvi. Naudas atmaksa par pasūtījumu tiek nodrošināta 1-2 darba dienu laikā.
      </p>
    </div>
    <button class="button" mat-raised-button color="primary" (click)="openConfirmOrder()">Apstiprināt</button>
  </form>
</div>
