import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { DefaultService, Order, OrderState } from 'src/app/services/api'
import { SnackBarService } from 'src/app/services/snackbar.service'
import { ORDER_STATE_META } from '../orders/orders.component'

export const saulesAptiekaClientId = 19464193

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit, OnDestroy {
  loading = false

  readonly states: OrderState[] = ['pending', 'quote', 'paid', 'packing', 'completed']

  readonly subscription = new Subscription()

  // TODO: tmp
  readonly state = this.states[Math.floor(Math.random() * this.states.length)]

  // TODO: tmp
  order?: Order

  constructor(
    public title: Title,
    private api: DefaultService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.paramMap.pipe(map((v) => +v.get('id')!)).subscribe((id) => {
        this.title.setTitle('Pasūtijums Nr. MSA ' + id.toString().padStart(6, '0'))

        this.loading = true
        this.api.getOrder(id, ['client', 'address']).subscribe({
          next: (order) => {
            this.order = order
            this.loading = false
          },
          error: (err) => {
            this.snackBar.httpError(err)
            this.loading = false
          },
        })
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  stateTitle(state: OrderState): string {
    return ORDER_STATE_META[state].title
  }

  stateIcon(state: OrderState): string {
    return ORDER_STATE_META[state].icon
  }

  isStateCompleted(state: OrderState): boolean {
    return this.order ? this.states.indexOf(state) <= this.states.indexOf(this.order!.state!) : false
  }
}
