import { transition, trigger, useAnimation } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ChildrenOutletContexts, Router, RoutesRecognized } from '@angular/router'
import { Subscription, filter, pairwise } from 'rxjs'
import { slideInAnimation } from './animations'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeAnimations', [
      transition('OrderPage => OrdersList', [useAnimation(slideInAnimation)]),
      transition('OrdersList => OrderPage', [
        useAnimation(slideInAnimation, {
          params: {
            transform1: 'translateX(100%)',
            transform3: 'translateX(-100%)',
          },
        }),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly subscription = new Subscription()

  constructor(
    public title: Title,
    private contexts: ChildrenOutletContexts,
    private router: Router
  ) {}

  ngOnInit() {
    this.title.setTitle('mana Saules Aptieka')

    this.subscription.add(
      this.router.events
        .pipe(
          filter((event): event is RoutesRecognized => event instanceof RoutesRecognized),
          pairwise(),
          filter(([, current]: [RoutesRecognized, RoutesRecognized]) =>
            ['terms', 'agreement', 'policy'].some((item) => current.url.includes(item))
          )
        )
        .subscribe(([previous]) => {
          if (sessionStorage.getItem('history')) {
            return
          }
          sessionStorage.setItem('history', previous.url)
        })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.animation
  }
}
