import { Component, OnInit } from '@angular/core'
import { Auth } from '@angular/fire/auth'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { getIdTokenResult } from 'firebase/auth'
import { from } from 'rxjs'
import { map, shareReplay, switchMap, tap } from 'rxjs/operators'
import { DefaultService, OrderState } from 'src/app/services/api'

interface OrderStateMeta {
  icon: string
  title: string
  description: string
}

export const ORDER_STATE_META: Record<OrderState, OrderStateMeta> = {
  canceled: { icon: 'thumb_down', title: 'Atcelts', description: 'Pasūtījums ir atcelts.' },
  pending: { icon: 'pending_actions', title: 'Sagatavošanā', description: 'Farmaceits veic piedāvājuma sagatavošanu.' },
  quote: { icon: 'local_pharmacy', title: 'Piedāvājums', description: 'Piedāvājums, ko sagatavojis farmaceits.' },
  paid: { icon: 'euro', title: 'Apmaksāts', description: 'Ir apmaksāts, un drīz būs sagatavots piegādei.' },
  packing: { icon: 'inventory_2', title: 'Pakošanā', description: 'Ir pakošanā, un drīz būs pieejams piegādei.' },
  completed: { icon: 'done', title: 'Nodots piegādei', description: 'Pasūtījums ir nodots piegādei.' },
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  loading = true

  readonly orders = this.api.getOrders().pipe(
    tap(() => (this.loading = false)),
    map((orders) => orders.sort((a, b) => (a.createdAt! > b.createdAt! ? -1 : 1)))
  )

  readonly photoUrl = this.auth.currentUser?.providerData.find((v) => v.photoURL)?.photoURL

  readonly userName = this.auth.currentUser?.providerData.find((v) => v.displayName || v.email)

  readonly client = from(getIdTokenResult(this.auth.currentUser!)).pipe(
    map((token) => +(token.claims.clientId as string)),
    switchMap((id) => this.api.getClient(id)),
    shareReplay(1)
  )

  constructor(
    public auth: Auth,
    public title: Title,
    public router: Router,
    private api: DefaultService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Pasūtījumi')
  }

  icon(state: OrderState): string {
    return ORDER_STATE_META[state].icon
  }

  tooltip(state: OrderState): string {
    return ORDER_STATE_META[state].description
  }

  async signOut() {
    await this.auth.signOut()
    this.router.navigate([''])
  }
}
